/** 
* 自动生成文件，请勿修改 
* @name: 考核:员工月度/年度/晋升考核表-考核维度配置
* @description: 员工月度/年度/晋升考核表-考核维度配置（三期新增）
* @create: 2024/9/29 15:41:49
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const dimensionConf = { 
	/** 1 summary:删除-批量
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/dimensionConf/batchDelete.do"), 
	/** 2 summary:保存/修改-单条
	description:参数：单条数据对象DimensionConfVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/dimensionConf/saveOrUpd.do"), 
	/** 3 summary:查询-分页
	description:参数：queryVO对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/dimensionConf/queryPage.do"), 
	/** 4 summary:查询-单条(根据主键)
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/dimensionConf/queryOne.do"), 
	/** 5 summary:保存/修改-批量
	description:参数：List<DimensionConfVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSaveOrUpd : new APIObject.postObject("/api/dimensionConf/batchSaveOrUpd.do"), 
	/** 6 summary:查询-全部不分页
	description:参数：queryVO对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/dimensionConf/queryAll.do"), 
	/** 7 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/dimensionConf/delete.do"), 
};
export default dimensionConf;