<!--
* @program: office_automation
* @author: ly
* @component:BankManager
* @description:
* @create: 2022-03-18 14:14
-->
<template>
    <div class="column-flex" style="overflow: auto">
        <a-spin :spinning="loading" tip="正在查询数据库...">
            <div style="display: flex;flex-wrap: wrap;overflow: auto;">
                <a-row :gutter="12" style="width: 100%;">
                    <a-col :span="5">
                        <div class="card-total">
                            <span class="card-total-title">余额合计</span>
                            <span class="card-total-money">￥{{allSum}}</span>
                        </div>
                    </a-col>
                    <a-col :span="5">
                        <div class="card-total">
                        <span class="card-total-title">其中贵州中色蓝图</span>
                        <span class="card-total-money">￥{{sum1}}</span>
                        </div>
                    </a-col>
                    <a-col :span="5">
                        <div class="card-total">
                        <span class="card-total-title">分公司</span>
                        <span class="card-total-money">￥{{sum2}}</span>
                        </div>
                    </a-col>
                    <a-col :span="5">
                        <div class="card-total">
                            <span class="card-total-title">中色</span>
                            <span class="card-total-money">￥{{sum3}}</span>
                        </div>
                    </a-col>
                    <a-col :span="2">
                        <div @click="addData" class="card-add">+</div>
                    </a-col>
                    <a-col :span="2">
                        <div class="card-add" @click="showMessage"><InfoCircleOutlined /></div>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col v-for="(item,idx) in dataSource" :span="6" :key="idx">
                        <a-card hoverable class="card-info" >
                            <template #title>
                            <span class="card-title">
                            {{item.remarks}}
                          </span>
                            </template>
                            <template #extra>
                                <a-popover placement="bottom" trigger="hover" >
                                    <template #content>
                                        <div class="card-edit">
                                            <span @click="showFlows(item)" title="查看详情" v-if="!item.visible">查看详情</span>
                                        </div>
                                        <div class="card-edit">
                                            <span @click="editData(item)">修改初始余额</span>
                                        </div>
                                        <div class="card-edit">
                                            <span @click="editData2(item)">修改账户别名</span>
                                        </div>
                                        <div class="card-edit">
                                            <a-popconfirm
                                                    @confirm="deleteData(item)"
                                                    cancel-text="否"
                                                    class="action-button"
                                                    ok-text="是"
                                                    title="确定删除吗?">
                                                <span>删除</span>
                                            </a-popconfirm>
                                        </div>
                                    </template>
                                    <a-button style="padding: 0 5px" type="link">
                                        <UnorderedListOutlined style="font-size: 22px;"/>
                                    </a-button>
                                </a-popover>
                            </template>
                            <a-row class="card-ye">
                                <a-row class="ye-q">
                                 <span style="font-size: 16px;font-weight: 400;color: #8A9FC4;">
                                    余额
                                </span>
                                    <span style="margin-left: 10px;font-size: 18px;font-weight: 400;color: #337EFF;">
                                    {{item.currentBlance.toFixed(2)}}元
                                </span>
                                </a-row>
                                <a-row class="ye-q ye-t">
                                    更新日期:{{new Date(item.updateTime).toLocaleDateString()}}
                                </a-row>
                            </a-row>
                            <LForm :labelSpan="8" :form-columns="getFormColumns(item)" :form-data="item" />
                            <template #actions>
                                <a-row :gutter="6" v-if="item.visible"
                                       style="width: 100%;padding: 1px 20px;display: flex;justify-content: center;align-items: center;">
                                    <a-button @click="back(item)" class="show-info"
                                              title="返回" type="link" >
                                        返回
                                    </a-button>
                                </a-row>
                            </template>
                        </a-card>
                    </a-col>
                    <a-col :span="18">
                        <div class="card-info" >
                            <a-tabs style="margin:10px" v-if="visible" v-model:activeKey="activeKey">
                                <a-tab-pane key="7" tab="流水汇总">
                                    <CardFlows :record="currentRecord" :show-bill="showBill"/>
                                </a-tab-pane>
                                <a-tab-pane key="6" tab="账单一览" v-if="showBill">
                                    <ShowBills :record="currentRecord"/>
                                </a-tab-pane>
                                <a-tab-pane key="1" tab="项目收入流水">
                                    <ProjectInflow :record="currentRecord"/>
                                </a-tab-pane>
                                <a-tab-pane key="2" tab="项目支出流水">
                                    <ProjectOutflow :record="currentRecord"/>
                                </a-tab-pane>
                                <a-tab-pane key="3" tab="其他收入">
                                    <OtherInflow :record="currentRecord"/>
                                </a-tab-pane>
                                <a-tab-pane key="4" tab="其他支出">
                                    <OtherOutflow :record="currentRecord"/>
                                </a-tab-pane>
                                <a-tab-pane key="5" tab="其他账户转入">
                                    <FromOtherBank :record="currentRecord"/>
                                </a-tab-pane>
                            </a-tabs>
                        </div>
                    </a-col>
                </a-row>
            </div>
        </a-spin>
    </div>
    <!-- 新增 -->
    <a-modal :footer="null"
             :title="null"
             destroyOnClose
             v-model:open="visible2">
        <span>账户类型：</span>
        <a-select :options="accountTypes"
                   style="width: 120px"
                  v-model:value="accountType"/>
        <LForm :form-columns="formColumns" :form-data="formData" :view-model="viewModel">
            <template #action="{record}">
                <a-button @click="saveData(record)" type="primary">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>

    <!-- 修改 -->
    <a-modal :destroyOnClose="true"
             :maskClosable="false"
             style="margin-top: 2%;" title="修改初始余额"
             v-model:open="visible_edit"
             width="300px">
        <a-form :label-col="8" :wrapper-col="14">
            <a-form-item label="初始余额(元)">
                <a-input-number style="width: 150px" v-model:value="formtData_edit.balance"/>
            </a-form-item>
        </a-form>
        <template #footer>
            <a-button @click="visible_edit =false" key="back">取消</a-button>
            <a-button @click="submit" key="back" type="primary">提交</a-button>
        </template>
    </a-modal>
    <a-modal :destroyOnClose="true"
             :maskClosable="false"
             title="修改账户别名"
             v-model:open="visible_edit2"
             width="300px">
        <a-form  :label-col="8" :wrapper-col="14">
            <a-form-item label="账户别名">
                <a-input style="width: 150px" v-model:value="formtData_edit.remarks"/>
            </a-form-item>
        </a-form>
        <template #footer>
            <a-button @click="visible_edit2 = false" key="back">取消</a-button>
            <a-button @click="submit" key="back" type="primary">提交</a-button>
        </template>
    </a-modal>
</template>

<script>
    import Icons from "@/page/common/Icons";
    import ProjectInflow from "@/page/Finance/FinancialManager/BankManager/ProjectInflow";
    import ProjectOutflow from "@/page/Finance/FinancialManager/BankManager/ProjectOutflow";
    import OtherInflow from "@/page/Finance/FinancialManager/BankManager/OtherInflow";
    import OtherOutflow from "@/page/Finance/FinancialManager/BankManager/OtherOutflow";
    import FromOtherBank from "@/page/Finance/FinancialManager/BankManager/FromOtherBank";
    import ShowBills from "@/page/Finance/FinancialManager/BankManager/ShowBills";
    import CardFlows from "@/page/Finance/FinancialManager/BankManager/CardFlows";

    import item_finance_bank_balance from "@/page/TableStruct/item_finance_bank_balance";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type";
    import LForm from "@/components/form/LForm";
    import bankBalance from "../../../../assets/api/bankBalance";

    import {InfoCircleOutlined,UnorderedListOutlined} from "@ant-design/icons-vue"

    export default {
        name: "BankManager",
        mixins: [Icons],
        components: {
            LForm,
            ProjectInflow, ProjectOutflow, OtherInflow, OtherOutflow, FromOtherBank,
            ShowBills, CardFlows,InfoCircleOutlined,UnorderedListOutlined
        },
        computed: {
            allSum() {
                let sum = 0;
                for (let i in this.allAccounts) {
                    sum += this.allAccounts[i].currentBlance
                }
                return sum.toFixed(2);
            },
            formColumns() {
                if (this.accountType === "1") {
                    return this.type1Cols
                } else {
                    return this.type2Cols
                }
            },
            dataSource() {
                let arr = this.allAccounts.filter(item => item.visible);
                if (arr.length > 0) {
                    return arr;
                } else {
                    return this.allAccounts
                }
            },
            /**
             * 分公司账户id:bb93e7c7-c2c7-4a52-897d-4f8ccf2bb660
             * 中色账户Id:313a0c6f-38d6-4213-bbfc-b784ac116a65
             * 之外的账户归属贵州中色蓝图
             * @returns {string}
             */
            sum1() {//贵州中色蓝图账户合计
                return (parseFloat(this.allSum) - parseFloat(this.sum2) - parseFloat(this.sum3)).toFixed(2);
            },
            sum2() {//分公司账户合计
                let sum = 0;
                let arr = this.allAccounts.filter(item => item.id === "bb93e7c7-c2c7-4a52-897d-4f8ccf2bb660")
                for (let i in arr) {
                    sum += arr[i].currentBlance
                }
                return sum.toFixed(2);
            },
            sum3() {//中色账户合计
                let sum = 0;
                let arr = this.allAccounts.filter(item => item.id === "313a0c6f-38d6-4213-bbfc-b784ac116a65")
                for (let i in arr) {
                    sum += arr[i].currentBlance
                }
                return sum.toFixed(2);
            },
            showBill() {
                return this.dataSource.length === 1 && this.dataSource[0].bankName === "现金账户";
            }
        },
        data() {
            return {
                type1Cols: [
                    new Column("银行名称", "bankName", ColumnType.String, true),
                    new Column("办卡支行名称", "subBank", ColumnType.String, true),
                    new Column("银行卡号", "bankNum", ColumnType.String, true),
                    new Column("初始余额(元)", "balance", ColumnType.Number, true),
                    new Column("初始余额日期", "balanceDate", ColumnType.Date, true),
                    new Column("账户别名", "remarks", ColumnType.String, true),
                ],
                type2Cols: [
                    new Column("初始余额(元)", "balance", ColumnType.Number, true),
                    new Column("初始余额日期", "balanceDate", ColumnType.Date, true),
                    new Column("账户别名", "remarks", ColumnType.String, true),
                ],
                accountTypes: [
                    {label: "银行", value: "1"},
                    {label: "现金", value: "2"},
                ],
                accountType: "1",//["1","2"],["银行","现金"]
                table: new item_finance_bank_balance(),
                viewModel: true,
                pageSize: 15,
                allAccounts: [],//所有的账户
                loading: false,//table的loading状态
                formData: null,//提供Form的formData
                activeKey: "7",
                visible: false,
                visible2: false,
                currentRecord: {},

                /** 编辑 **/
                visible_edit: false,
                visible_edit2: false,
                formtData_edit: null,

                cardAdd_flag: true,
            }
        },
        created() {
            this.formColumns = this.table.getFormColumns();
            this.init()
        },
        methods: {
            init() {
                this.visible = false;
                this.visible2 = false;
                this.loading = true;
                bankBalance.queryAll.post({}, res => {
                    this.loading = false;
                    if (res.data.flag) {
                        this.allAccounts = res.data.data;
                        this.allAccounts = this.allAccounts.sort((a,b)=>{
                            return new Date(a.creatTime).valueOf() - new Date(b.creatTime).valueOf()
                        })
                    } else {
                        this.allAccounts = [];
                        this.$message.error(res.data.msg);
                    }

                })
            },
            refresh() {
                this.init()
            },
            addData() {
                this.visible2 = true;
                this.formData = this.table.getFormData();
                this.viewModel = false;
            },
            editData(record) {
                this.visible_edit = true;
                this.formtData_edit = record;
            },
            editData2(record) {
                this.visible_edit2 = true;
                this.formtData_edit = record;
            },
            submit() {
                bankBalance.saveOrUpdate.post(this.formtData_edit, res => {
                    if (res.data.flag) {
                        this.visible_edit = false;
                        this.visible_edit2 = false;
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            saveData(record) {
                if(record.id){
                    bankBalance.saveOrUpdate.post(record, res => {
                        if (res.data.flag) {
                            this.init();
                            this.$message.success(res.data.msg);

                        } else {
                            this.$message.error(res.data.msg);
                        }
                    })
                }
                else{
                    if (this.accountType === "2") {
                        record.bankName = "现金账户";
                        record.subBank = "现金账户";
                        record.bankNum = "0000000000"
                    }
                    record.accountType = this.accountType;
                    bankBalance.saveOrUpdate.post(record, res => {
                        if (res.data.flag) {
                            this.init();
                            this.$message.success(res.data.msg);
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    })
                }

            },
            showDetail(record) {
                this.formData = record;
                this.viewModel = true;
                this.visible2 = true;
                this.currentRecord = record;
            },
            deleteData(item) {
                if (item.createUser !== this.$store.getters.userInfo.id) {
                    this.$message.error("不能删除他人创建的账户，请联系管理员");
                    return;
                }
                let data = {
                    id: item.id
                };
                bankBalance.delete.urlPost(data, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.init()
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
            },
            getFormColumns(item) {
                if (item.accountType === "1") {
                    return this.type1Cols;
                } else {
                    return this.type2Cols;
                }
            },
            showFlows(item) {
                this.currentRecord = item;
                this.visible = true;
                item.visible = true;
                this.activeKey = "7";
                this.cardAdd_flag = false;
            },
            back(item) {
                item.visible = false;
                this.visible = false;
                this.cardAdd_flag = true;
            },
            handleUpdate(data) {
                const keys = Object.keys(data);
                for (const key of keys) {
                    this.record[key] = data[key];
                }
            },
            showMessage(){
                this.$message.info("让账户的金额变动的流水日期需要在【账户初始余额日期】之后，录入在【账户初始余额日期】之前的流水会导致该银行卡出现各部分流水金额统计起来跟系统余额不同的情况。")
            }
        },

    }
</script>

<style scoped>

</style>
