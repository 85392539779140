<!--
* @program: TYHOA2 
* @author: ly
* @component:ProjectIncomeRecord 
* @description: 项目收入记录
* @create: 2024-07-25 15:46
-->
<template>
    <div class="form-title">收入列表
        <a-button  type="link"  @click="refresh">
            <template #icon><SyncOutlined /></template>
        </a-button>
        <div style="margin:0 5px">发票号码：</div>
        <a-input placeholder="发票号码" style="width: 150px" v-model:value="invoiceNumber" />
    </div>
    <div style="display: flex;margin-left: 30px;align-items: center;">
        <a-button type="dashed" v-if="!viewModel" @click="modalShow(false)">
            <template #icon><PlusOutlined /></template>添加收款(无发票)
        </a-button>
        <div style="margin-left: 10px;">合计: <span class="form-value">{{summary.toFixed(2)}} 元</span></div>
        <div style="margin-left: 10px;">无发票合计: <span class="form-value">{{noInvoiceAmount.toFixed(2)}} 元</span></div>
    </div>
    <LTable style="height: 100%" :columns="tableColumns" :data-source="source">
        <template #action="{index,record}">
            <div class="action-column">
                <a-button  type="link"  @click="showDetail(index,record)">
                    <template #icon><SearchOutlined /></template>
                </a-button>
                <a-button v-if="!viewModel" type="link"  @click="editDetail(index,record)">
                    <template #icon><EditOutlined /></template>
                </a-button>
                <a-popconfirm
                        v-if="!viewModel"
                        ok-text="是"
                        cancel-text="否"
                        class="action-button"
                        title="确定删除吗?"
                        @confirm="deleteData(index,record)">
                    <DeleteOutlined  style="color: red" class="pointer"/>
                </a-popconfirm>
            </div>
        </template>
    </LTable>
    <a-modal v-model:open="visible"
             destroy-on-close
             title="收款"
             :maskClosable="vModel"
             :footer="null">
        <a-row v-if="!vModel">
            <a-col :span="6">
                <span class="form-value">选择入账账户</span>
            </a-col>
            <a-col :span="18">
                <a-select :fieldNames="fieldNames" v-model:value="bankId" style="width: 100%" :options="bankCards"/>
            </a-col>
        </a-row>
        <LForm :view-model="vModel" :form-columns="formColumns" :form-data="formData">
            <template #action="{record}">
                <a-button @click="saveData(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
</template>

<script>
    import {PlusOutlined,DeleteOutlined,SyncOutlined,SearchOutlined,EditOutlined} from "@ant-design/icons-vue";
    import LForm from "../../../components/form/LForm";
    import LTable from "../../../components/table/LTable";
    import collectionInfo from "../../../assets/api/collectionInfo";
    import item_collection_info from "../../TableStruct/item_collection_info";
    export default {
        name: "ProjectIncomeRecord",
        props:["record","bankCards","viewModel"],
        inject:["pProjectId"],
        components:{
            PlusOutlined,DeleteOutlined,EditOutlined,
            SyncOutlined,SearchOutlined,LForm,LTable
        },
        computed:{
            projectId(){
                if(this.record){
                    return this.record.itemId?this.record.itemId:this.record.id
                }
                else{
                    return this.pProjectId;
                }
            },
            summary(){
                let sum=0;
                for(let i in this.source){
                    sum += this.source[i].collectionAmount;
                }
                return sum;
            },
            source(){
                if(this.invoiceNumber){
                    return this.dataSource.filter(item=>{
                        if(item.invoiceNumber){
                            return item.invoiceNumber.includes(this.invoiceNumber)
                        }
                        else{
                            return false
                        }
                    })
                }
                else{
                    return this.dataSource
                }
            },
            noInvoiceAmount(){//无发票合计
                let sum=0;
                for(let item of this.dataSource){
                    if(!item.invoiceRecord){
                        sum += item.collectionAmount;
                    }
                }
                return sum;
            }
        },
        data(){
            return{
                table:new item_collection_info(),
                dataSource:[],//提供table的DataSource
                tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                loading:false,//table的loading状态
                formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                formData:null,//提供Form的formData
                visible:false,
                vModel:true,
                hasInvoice:true,
                fieldNames:{key:'id', value: 'id',label:"remarks"},
                bankId:undefined,
                invoiceNumber:null
            }
        },
        methods:{
            init(){
                this.tableColumns = this.table.getTableColumns();
                this.formColumns = this.table.getFormColumns();
                let data = {itemId:this.projectId};
                this.loading = true;
                collectionInfo.queryAll.urlPost(data,res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                        for(let item of this.dataSource){
                            if(item.invoiceRecord){
                                item.invoiceNumber = item.invoiceRecord.invoiceNumber
                            }
                        }
                    }
                })

            },
            refresh(){
                this.init()
            },
            modalShow(bool){
                this.hasInvoice = bool;
                this.vModel = false;
                this.visible = true;
                this.formData = this.table.getFormData();
                if(this.record && this.record.sigCompany){
                    this.formData.receiverId = this.record.sigCompany;
                }
                if(bool){
                    this.formColumns = this.table.getFormColumns().filter(item=>item.group === 0);
                }
                else{
                    this.formColumns = this.table.getFormColumns();
                }
            },
            showDetail(index,record){
                this.hasInvoice = !!record.invoiceId;
                this.visible = true;

                this.vModel = true;
                this.formData = record;
                this.formColumns = this.table.getFormColumns();
            },
            editDetail(index,record){
                this.hasInvoice = true;
                this.visible = true;

                this.vModel = false;
                this.formData = record;
                this.formColumns = this.table.getFormColumns();
            },
            deleteData(index,record){
                let data = {id:record.id};
                collectionInfo.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.dataSource.splice(index,1);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData(record){
                if(record.id){
                    collectionInfo.update.post(record,res=>{
                        if(res.data.flag){
                            this.init();
                            this.visible = false;
                            this.$message.success(res.data.msg);
                        }
                        else{
                            this.$message.error(res.data.msg);
                        }
                    });
                }
                else{
                    if(this.bankId){
                        record.bankId = this.bankId;
                        record.itemId = this.projectId;
                        collectionInfo.save.post(record,res=>{
                            if(res.data.flag){
                                this.init();
                                this.visible = false;
                                this.$message.success(res.data.msg);
                            }
                            else{
                                this.$message.error(res.data.msg);
                            }
                        });
                    }
                    else{
                        this.$message.error("还未选择入账账户")
                    }
                }
            },
            deleteCheck(record){
                let time = parseInt(localStorage.getItem("time"));
                return new Date(record.createTime).toLocaleDateString() === new Date(time).toLocaleDateString();
            },
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>