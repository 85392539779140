/** 
* 自动生成文件，请勿修改 
* @name: 项目:发票开具记录管理模块(销项发票)
* @description: 发票开具记录管理模块-增删改查(二期新增)
* @create: 2024/9/29 15:41:49
* @swagger: 2.0
* 合计:9条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const invoiceRecord = { 
	/** 1 summary:删除-批量
	description:参数：数据的主键id,逗号分隔：1,2,3,4,5.......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/invoiceRecord/batchDelete.do"), 
	/** 2 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/invoiceRecord/delete.do"), 
	/** 3 summary:查询-不分页（全部）
	description:参数：InvoiceQueryVO invoiceQueryVO发票开具记录封装对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/invoiceRecord/queryAll.do"), 
	/** 4 summary:销项发票数量统计：年、月、条数、金额等）
	description:参数：年度或月度（以开票日期算）
	method:post
	params:需要配合Column函数使用
	*/ 
	statCount : new APIObject.postObject("/api/invoiceRecord/statCount.do"), 
	/** 5 summary:查询-分页
	description:参数：InvoiceQueryVO invoiceQueryVO发票开具记录封装对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/invoiceRecord/queryPage.do"), 
	/** 6 summary:根据id查询
	description:参数：id(主键id)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryById : new APIObject.postObject("/api/invoiceRecord/queryById.do"), 
	/** 7 summary:查询-开票收款情况
	description:参数：PaySituationQueryVO queryVO发票接收记录封装对象
	method:post
	params:需要配合Column函数使用
	*/ 
	collectSituation : new APIObject.postObject("/api/invoiceRecord/collectSituation.do"), 
	/** 8 summary:新增/修改-单条
	description:参数：InvoiceRecordVO invoiceRecordVO发票开具记录对象封装
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpdate : new APIObject.postObject("/api/invoiceRecord/saveOrUpdate.do"), 
	/** 9 summary:冲红-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	flushRed : new APIObject.postObject("/api/invoiceRecord/flushRed.do"), 
};
export default invoiceRecord;