<!--
* @program: TYHOA2 
* @author: ly
* @component:DepartmentTaskViewer 
* @description: 部门任务管理
* @create: 2024-08-02 11:23
-->
<template>
    <div class="column-flex" style="position: relative;overflow: hidden">
        <div class="search-bar">
            <a-button @click="refresh" type="dashed">
                <template #icon>
                    <SyncOutlined/>
                </template>
            </a-button>
            <div style="margin: 10px;color: black">部门:</div>
            <a-select v-model:value="defaultQuery.departmentId"
                      style="width: 200px"
                      @change="init"
            >
                <a-select-option v-for="department in myDepartments" :key="department.id">
                    {{ department.name }}
                </a-select-option>
            </a-select>
            <a-radio-group ref="ref3" @change="doSearch" style="margin-left:10px" v-model:value="state">
                <a-radio ref="ref1" value="0">未完成</a-radio>
                <a-radio ref="ref2" value="1">已完成</a-radio>
                <a-radio value="2">全部</a-radio>
            </a-radio-group>
            <a-divider type="vertical" style="height:32px;border-left: #022d5c solid;" />
            <a-input @change="doSearch" placeholder="项目名称"  style="width: 150px" v-model:value="filter.itemName" />
            <a-input @change="doSearch" placeholder="任务负责人" style="width: 150px" v-model:value="filter.taskLeaderName" />
            <a-button type="primary" @click="tourVisible=true">功能说明</a-button>
        </div>
        <div style="width: 100%;height: 100%;">
            <LTable :columns="taskColumns" :data-source="dataSource" :loading="loading">
            <template #expandedRow="{children}">
                <a-row>
                    <a-col :span="8">
                        <div class="form-label" style="margin-right: 10px">已分配子任务，分配部门：</div>
                    </a-col>
                    <a-col :span="16">
                        <div class="form-value" >
                             <span style="margin-left:10px " v-for="child in children" :key="child.id">{{child.departmentName}}</span>
                        </div>
                    </a-col>
                </a-row>
            </template>
            <template #action="{record}">
                <div v-if="record.itemType !=='9'" class="action-column">
                    <a-button @click="editDetail(record)" class="action-button" type="link">
                        <ApartmentOutlined class="pointer"/>
                    </a-button>
                    <a-tooltip  title="指定负责人">
                        <a-button  class="action-button" type="link" @click="setLeader(record)">
                            <UserAddOutlined  class="pointer"/>
                        </a-button>
                    </a-tooltip>
                    <a-tooltip v-if="!record.state" title="提交任务完成">
                        <a-button  class="action-button" type="link" @click="finishTask(record)">
                            <CheckOutlined  class="pointer"/>
                        </a-button>
                    </a-tooltip>
                    <a-tooltip v-if="!record.state" title="任务延期申请">
                        <a-button  class="action-button" type="link" @click="delayApply(record)">
                            <FallOutlined  style="color: red" class="pointer"/>
                        </a-button>
                    </a-tooltip>
                    <a-tooltip v-if="record.state && record.pid ==='0' && !record.finishCheck" title="发起项目完结申请">
                        <a-button  class="action-button" type="link" @click="itemFinishApply(record)">
                            <AuditOutlined style="color: #0a8b03" class="pointer"/>
                        </a-button>
                    </a-tooltip>
                </div>
                <div v-else class="action-column">
                    <a-tooltip v-if="!record.state" title="提交任务完成">
                        <a-button  class="action-button" type="link" @click="finishTask(record)">
                            <CheckOutlined  class="pointer"/>
                        </a-button>
                    </a-tooltip>
                </div>
            </template>
        </LTable>
        </div>
        <a-drawer :closable="false"
                  :get-container="false"
                  :style="{ position: 'absolute' }"
                  @close="visible = false"
                  destroy-on-close
                  height="90%"
                  placement="bottom"
                  v-model:open="visible">
            <a-tabs style="height: 100%" tabPosition="left" v-model:activeKey="activeKey">
                <a-tab-pane style="height: 100%;overflow: auto" key="1" tab="项目信息">
                    <ProjectInfoView  />
                </a-tab-pane>
                <a-tab-pane  style="height: 100%;overflow: auto" key="2" tab="任务信息">
                    <ProjectTaskInfo  :record="currentRecord" :view-model="true"/>
                </a-tab-pane>
                <a-tab-pane  style="height: 100%;overflow: auto" key="3" tab="任务工序">
                    <TaskProcessList :departmentId="defaultQuery.departmentId" :task="currentRecord" :view-model="false"/>
                </a-tab-pane>
                <a-tab-pane  style="height: 100%;overflow: auto" key="5" tab="项目周报">
                    <WorkTaskReport :task="currentRecord" :view-model="true"/>
                </a-tab-pane>
                <a-tab-pane  style="height: 100%;overflow: auto" key="4" tab="月度绩效分配">
                    <MonthlyArrange :task="currentRecord" :departmentId="defaultQuery.departmentId" :view-model="false"/>
                </a-tab-pane>
                <a-tab-pane style="height: 100%;overflow: auto" key="6" tab="项目合同"><ProjectContractInfo :task="currentRecord" :view-model="true"/></a-tab-pane>
                <a-tab-pane style="height: 100%;overflow: auto" key="9" tab="其他文件"><ProjectRelateFiles :view-model="false"/></a-tab-pane>
                <a-tab-pane style="height: 100%;overflow: auto" key="10" tab="项目质检"><TaskQC @update="handleUpdate" :task="currentRecord" :view-model="false"/></a-tab-pane>
                <a-tab-pane style="height: 100%;overflow: auto" key="7" tab="项目收款"><ProjectIncomeView :task="currentRecord"/></a-tab-pane>
                <a-tab-pane style="height: 100%;overflow: auto" key="8" tab="外协支付"><ProjectOutcome /></a-tab-pane>
                <a-tab-pane style="height: 100%;overflow: auto" key="b" tab="项目归档"><ProjectArchive :view-model="false"/></a-tab-pane>
                <a-tab-pane style="height: 100%;overflow: auto" key="a" tab="开票信息填写表"><ProjectPaymentApply :task="currentRecord" :departmentId="defaultQuery.departmentId"/></a-tab-pane>
            </a-tabs>
        </a-drawer>
    </div>
    <a-modal v-model:open="visible2"
             destroy-on-close
             title="提交任务完成情况"
             :footer="null">
        <a-row style="margin: 10px">
            <a-col :span="8">
                <div class="form-label" style="margin-right: 10px">完成时间</div>
            </a-col>
            <a-col :span="16">
                <div class="form-value" >
                    <a-date-picker style="width: 200px" v-model:value="taskDate" />
                </div>
            </a-col>
        </a-row>
        <a-row style="margin: 10px">
            <a-col :span="8">
                <div class="form-label" style="margin-right: 10px">完成描述</div>
            </a-col>
            <a-col :span="16">
                <div class="form-value" >
                    <a-input style="width: 200px" v-model:value="currentRecord.describe" />
                </div>
            </a-col>
        </a-row>
        <a-button @click="saveData"  type="primary">
            提交
        </a-button>
    </a-modal>
    <a-modal v-model:open="visible3"
             destroy-on-close
             width="800px"
             title="任务延期申请"
             :footer="null">
        <delayApply :record="currentRecord" type="1" />
    </a-modal>
    <a-modal v-model:open="visible4"
             destroy-on-close
             title="指定项目负责人"
             :footer="null">
        <DepartmentUserPicker :department-id="defaultQuery.departmentId" v-model:value="user" />
        <a-button @click="saveLeader"  type="primary">
            提交
        </a-button>
    </a-modal>

    <a-modal v-model:visible="visible5"
             destroyOnClose
             :footer="null"
             :title="null">
        <div class="form-title">项目完结申请说明</div>
        <a-textarea placeholder="项目完结申请说明" :auto-size="{ minRows: 2, maxRows: 6 }" v-model:value="describe"/>
        <a-divider />
        <a-popconfirm
                ok-text="是"
                cancel-text="否"
                class="action-button"
                title="确认发起申请吗?无法撤回"
                @confirm="finishApply">
            <a-button  type="primary" style="margin: 20px">
                <span style="padding: 0 20px;">发起申请</span>
            </a-button>
        </a-popconfirm>
    </a-modal>
    <a-tour v-model:current="tCurrent" :open="tourVisible" :steps="tSteps" @close="()=>{tourVisible=false;tCurrent=0}" />
</template>

<script>
    import {
        SyncOutlined,ApartmentOutlined,CheckOutlined,FallOutlined,UserAddOutlined,AuditOutlined
    } from "@ant-design/icons-vue";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type"
    import LTable from "../../../components/table/LTable";
    import ProjectInfoView from "../ProjectManager/ProjectInfoView";
    import ProjectTaskInfo from "../ProjectTask/ProjectTaskInfo";
    import itemTask from "../../../assets/api/itemTask";
    import {ArrayFilter} from "../../../assets/utils/general";
    import DelayApply from "../DelayManager/DelayApply";
    import DepartmentUserPicker from "../../common/DepartmentUserPicker";
    import TaskProcessList from "./TaskProcessList";
    import MonthlyArrange from "./MonthlyArrange";
    import WorkTaskReport from "../../PersonalCenter/WorkCenter/WorkTaskReport/WorkTaskReport";
    import dayjs from "dayjs";
    import ProjectContractInfo from "../ProjectContract/ProjectContractInfo";
    import ProjectIncomeView from "../ProjectIncome/ProjectIncomeView";
    import ProjectOutcome from "../ProjectOutcome/ProjectOutcome";
    import ProjectRelateFiles from "../ProjectRelateFiles/ProjectRelateFiles";
    import TaskQC from "./TaskQC/TaskQC";
    import {computed, h} from 'vue'
    import itemInfo from "../../../assets/api/itemInfo";
    import ProjectPaymentApply from "./ProjectPaymentApply";
    import ProjectArchive from "../ProjectManager/ProjectArchive";

    export default {
        name: "DepartmentTaskViewer",
        components: {
            SyncOutlined,CheckOutlined,FallOutlined,UserAddOutlined,
            ApartmentOutlined,AuditOutlined,
            DepartmentUserPicker,TaskProcessList,MonthlyArrange,WorkTaskReport,
            ProjectContractInfo,ProjectIncomeView,ProjectOutcome,
            ProjectRelateFiles,TaskQC,LTable,ProjectInfoView,ProjectTaskInfo,
            DelayApply,ProjectPaymentApply,ProjectArchive
        },
        provide() {
            return {
                pTaskId: computed(() => this.currentRecord.id),
                pProjectId:computed(() => this.currentRecord.itemId),
            }
        },
        data() {
            return {
                taskList: [],//
                taskColumns: [
                    new Column("项目类型", "itemType", ColumnType.String)
                        .setDictEnum("itemType").setTableView(80),
                    new Column("任务类型", "taskType", ColumnType.ColorText).setTableView(80),
                    new Column("项目名称", "itemName", ColumnType.String).setTableView(250),
                    new Column("任务负责人", "taskLeaderName", ColumnType.String).setTableView(80),
                    new Column("任务开始时间", "startDate", ColumnType.Date).setTableView(80),
                    new Column("完成期限", "endDate", ColumnType.Date).setTableView(80),
                    new Column("剩余时间", "deadLine", ColumnType.ColorText).setTableView(80),
                    new Column("总进度", "taskMonthRatios", ColumnType.Array).setTableView(80)
                        .setArrayStringfy((item)=>{
                            return `${item.month} - ${item.ratio}%`
                        }, (a,b)=>{return a.ratio - b.ratio}),
                    new Column("完成状态","state",ColumnType.String)
                        .setDictEnum("state"),
                    new Column("备注", "remarks", ColumnType.String).setTableView(80),
                    new Column("操作", "actions", "actions", false).setTableView(100)
                ],
                currentRecord: {},
                loading: false,
                defaultQuery: {
                    departmentId: null,
                },
                filter:{},
                visible: false,
                visible2: false,
                visible3: false,
                visible4: false,
                visible5: false,
                dataSource: [],
                myDepartments: [],
                activeKey:"1",
                vModel:true,
                state:"0",
                taskDate:null,
                user:null,
                describe:"本项目工作已完成，质检已完成，归档已完成，收款已完成，可以申请项目完结。",
                tourVisible:false,
                tCurrent:0,
                tSteps:[
                    {
                        title: '未完成任务',
                        description: '可以管理该任务，完成任务，延长任务限时，指定任务负责人',
                        target: () => this.$refs.ref1.$el,
                    },
                    {
                        title: '已完成任务',
                        description: h('div',[
                            h("div","1、可以继续完善【质检】【收款】【归档】信息"),
                            h("div","2、已提交完成的任务，可以发起【项目完结审核】"),
                            h("div",{style:{"color":"red"}},"3、发起【项目完结审核】需要完成【质检】【收款】【归档】"),
                        ]),
                        target: () => this.$refs.ref2.$el,
                    },
                    {
                        title: '虚拟项目管理请在专门的菜单中操作',
                        description: h('div',[
                            h("div",{style:{"color":"red"}},"下一年度的虚拟项目创建之前请先将本年度的提交完成"),
                        ]),
                        target: () => this.$refs.ref3.$el,
                    },
                ]
            }
        },
        methods: {
            init() {
                this.taskList = [];
                this.loading = true;
                let today = dayjs();
                itemTask.queryAll.urlPost(this.defaultQuery,res=>{
                    this.loading = false;
                    if (res.data.flag) {
                        this.taskList = res.data.data.sort((a, b) => {
                            return b.createTime- a.createTime;
                        });
                        this.taskList = this.taskList.map(item=>{
                            if(item.endDate && !item.state){
                                let endDate = dayjs(item.endDate)
                                let num = endDate.diff(today, 'day');
                                if(num>0){
                                    item.deadLine = {text:`剩余 ${num} 天`,color:"#00338b"}
                                }
                                else{
                                    item.deadLine = {text:`已超时 ${0-num} 天`,color:"#c64634"}
                                }
                            }
                            item.taskType = item.pid === "0"? {text:"主任务",color:"#00338b"}:{text:"子任务",color:"#c64634"}

                            return item
                        });
                        this.doSearch()
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })

            },
            doSearch() {
                let list = []
                switch (this.state) {
                    case "0":{
                        list = this.taskList.filter(item => !item.state);
                        break;
                    }
                    case "1":{
                        list = this.taskList.filter(item => item.state);
                        break;
                    }
                    case "2":{
                        list = this.taskList;
                        break
                    }
                }
                this.dataSource = ArrayFilter(list,this.filter);
            },
            refresh() {
                this.init();
            },
            editDetail(record) {
                this.currentRecord = record;
                this.visible = true;
                this.vModel = false;
            },
            finishTask(record){
                this.currentRecord = record;
                this.visible2 = true;
            },
            delayApply(record){
                this.currentRecord = record;
                this.visible3 = true;
            },
            saveData(){
                /** 以当前时间为完成时间
                 let time = localStorage.getItem("time");
                 record.completeDate = parseInt(time);
                 */
                this.currentRecord.completeDate = this.taskDate.valueOf();
                itemTask.saveOrUpd.post(this.currentRecord,res=>{
                    if(res.data.flag){
                        this.visible2 = false;
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            setLeader(record){
                this.currentRecord = record;
                this.visible4 = true;
            },
            saveLeader(){
                this.currentRecord.taskLeader = this.user;
                itemTask.saveOrUpd.post(this.currentRecord,res=>{
                    if(res.data.flag){
                        this.visible4 = false;
                        this.currentRecord.taskLeaderName = "已替换"
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            handleUpdate(data){
                Object.assign(this.currentRecord,data);
            },
            itemFinishApply(record){
                this.currentRecord = record;
                itemInfo.queryOne.urlPost({id:record.itemId}, res => {
                    if (res.data.flag) {
                        let data = res.data.data;
                        switch (data.completeFlag) {
                            case "0":{
                                this.visible5 = true;
                                break;
                            }
                            case "1":{
                                this.$message.error("已发起完结申请，请等待审核");
                                break;
                            }
                            case "2":{
                                this.currentRecord.finishCheck = true;
                                this.$message.success("本项目已完结!");
                                break;
                            }
                            case "3":{
                                this.visible5 = true;
                                break;
                            }
                        }
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
            },
            finishApply(){
                if(!this.describe){
                    this.$message.error("请输入完结说明");
                    return
                }
                let data = {
                    completeFlag:"1",
                    id:this.currentRecord.itemId,
                    describe:this.describe
                };
                itemInfo.audit.urlPost(data,res=>{
                    if(res.data.flag){
                        this.currentRecord.finishCheck = true;
                        this.$message.success(res.data.msg);
                        this.visible5 = false;
                    }
                    else{
                        this.$message.error(res.data.msg)
                    }
                });
            }
        },
        async created() {
            this.myDepartments = JSON.parse(await this.$getItem("__myDepartments"));
            this.defaultQuery.departmentId = this.myDepartments[0].id;
            this.describe += "申请人："+ await this.$getItem("__myName")
            this.init();
        }
    }
</script>

<style scoped>

</style>