<!--
* @program: office_automation
* @author: ly
* @component:OtherInflow
* @description: 其他收入（除项目收入之外）
* @create: 2022-02-16 16:55
-->
<template>

    <div class="center-flex">
        <span class="statistic-label"> 合计：{{summary}} 元</span>
        <MonthMoneySum :start-date="record.balanceDate" :records="argument.dataSource" date-seg="collectDate" sum-seg="collectAmount" />
    </div>
    <div style="height:600px">
    <LTable  :data-source="argument.dataSource" :columns="argument.tableColumns" :view-model="true" :page-size="pageSize">
        <template #action="{record}">
            <div class="action-column">
                <a-button class="action-button" type="link" @click="showDetail(record)">
                    <Search class="pointer"/>
                </a-button>
            </div>
        </template>
    </LTable>
    </div>
    <a-modal v-model:open="visible"
             destroyOnClose
             :footer="null"
             :title="null">
        <LForm  :form-data="formArg.formData" :form-columns="formArg.formColumns" :view-model="viewModel" >
            <template #action="{record}">
                <a-button @click="saveData(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>

</template>

<script>
import dayjs from "dayjs";
import Icons from "@/page/common/Icons";
import LTable from "@/components/table/LTable";
import LForm from "@/components/form/LForm";
import item_finance_income from "@/page/TableStruct/item_finance_income";
import income from "@/assets/api/income";
import MonthMoneySum from "@/page/Finance/FinancialManager/BankManager/MonthMoneySum";
export default {
    name: "OtherInflow",
    props:["record","value"],
    mixins:[Icons],
    components: {
      LTable,LForm,MonthMoneySum
    },
    computed:{
        summary(){
            let sum=0;
            for(let i in this.argument.dataSource){
                let date = this.argument.dataSource[i].collectDate;
                if(date.isBefore(dayjs(this.record.balanceDate))){
                    continue
                }
                sum += this.argument.dataSource[i].collectAmount*100;
            }
            return sum/100;
        }
    },
    watch:{
        record(){
            this.init()
        }
    },
    data(){
        return{
            table: new item_finance_income(),
            viewModel:true,
            pageSize:15,
            argument:{
                dataSource:null,//提供table的DataSource
                tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                loading:false,//table的loading状态
            },
            formArg:{
                formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                formData:null,//提供Form的formData
            },
            visible:false,
        }
    },
    methods:{
        init(){
            this.visible = false;
            this.argument.loading = true;
            this.argument.dataSource = [];
            let data = {
                bankId:this.record.id
            };
            income.queryAll.post(data,res=>{
                this.argument.loading = false;
                if(res.data.flag){
                    this.argument.dataSource = res.data.data;
                    this.$emit("update:value",{value:this.summary,loading:false});
                }
                else{
                    this.$emit("update:value",{value:0,loading:false});
                }
            })
        },
        refresh(){
            this.init()
        },
        saveData(record){
            income.saveOrUpdate.post(record,res=>{
                if(res.data.flag){
                    this.init();
                    this.$message.success(res.data.msg);
                }
                else{
                    this.$message.error(res.data.msg);
                }
            })
        },
        showDetail(record){
            this.formArg.formData = record;
            this.viewModel = true;
            this.visible = true;
        },
    },
    created() {
        this.argument.tableColumns = this.table.getTableColumns();
        this.formArg.formColumns = this.table.getFormColumns();
        this.init();
    }
}
</script>

<style scoped>

</style>
