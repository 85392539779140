/** 
* 自动生成文件，请勿修改 
* @name: 资产:设备管理模块
* @description: 设备管理模块-增删改查
* @create: 2024/9/29 15:41:49
* @swagger: 2.0
* 合计:8条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const device = { 
	/** 1 summary:查询-所有数据
	description:参数：1、queryText:设备名称、编号 模糊查询 2、categoryId：所属设备分类id:小类id 3、state：设备启用状态：0：停用，1：启用 4、mainFlag：是否只显示主设备：0：显示全部；1：只显示主设备 5、useState：设备借出状态：0：未借出（可以借用的），1：已借出（不能借用的） 6、userId：当前使用人id(这里传值时，上面的useState就得传1) 7、departId：部门id,(这里传值时，上面的useState就得传1)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/device/queryAll.do"), 
	/** 2 summary:查询-单条
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/device/queryOne.do"), 
	/** 3 summary:保存/修改-批量
	description:参数：List<DeviceVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSaveOrUpd : new APIObject.postObject("/api/device/batchSaveOrUpd.do"), 
	/** 4 summary:删除-单条
	description:参数：单条数据的主键id,若删除的是主设备，将释放掉关联的附属设备（清除对应mainId）,其附属设备可挂接给其他主设备
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/device/delete.do"), 
	/** 5 summary:启用/停用-批量(可单条)
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchModifyState : new APIObject.postObject("/api/device/batchModifyState.do"), 
	/** 6 summary:查询-分页
	description:参数：1、queryText:设备名称、编号 模糊查询 2、categoryId：所属设备分类id:小类id 3、state：设备启用状态：0：停用，1：启用 4、mainFlag：是否只显示主设备：0：显示全部；1：只显示主设备 5、useState：设备借出状态：0：未借出（可以借用的），1：已借出（不能借用的） 6、userId：当前使用人id(这里传值时，上面的useState就得传1) 7、departId：部门id,(这里传值时，上面的useState就得传1)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/device/queryPage.do"), 
	/** 7 summary:保存/修改-单条
	description:参数：单条数据对象DeviceVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/device/saveOrUpd.do"), 
	/** 8 summary:删除-批量
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......若删除的是主设备， 将释放掉关联的附属设备（清除对应mainId）,其附属设备可挂接给其他主设备
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/device/batchDelete.do"), 
};
export default device;