/** 
* 自动生成文件，请勿修改 
* @name: 考核:员工月度考核维度得分表
* @description: 员工月度考核维度得分表（三期新增）
* @create: 2024/9/29 15:41:49
* @swagger: 2.0
* 合计:14条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const assessmentUser = { 
	/** 1 summary:删除-批量
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/assessmentUser/batchDelete.do"), 
	/** 2 summary:修改：多条
	description:参数：List<AssessmentUserVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchUpd : new APIObject.postObject("/api/assessmentUser/batchUpd.do"), 
	/** 3 summary:同意/不同意-多条id,逗号分隔
	description:参数：多条id,逗号分隔
	method:post
	params:需要配合Column函数使用
	*/ 
	agree : new APIObject.postObject("/api/assessmentUser/agree.do"), 
	/** 4 summary:查询(得到某用户某年月的考核总得分)
	description:参数：userId用户主键，yearMonth考核年月（例：2022-05）
	method:post
	params:需要配合Column函数使用
	*/ 
	queryScore : new APIObject.postObject("/api/assessmentUser/queryScore.do"), 
	/** 5 summary:查询-单条(根据主键)
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/assessmentUser/queryOne.do"), 
	/** 6 summary:查询-（需要自己打分的）当前登录用户为评分人
	description:参数：无参数，后台根据session自己获取当前登录用户
	method:post
	params:需要配合Column函数使用
	*/ 
	queryByJudgeUser : new APIObject.postObject("/api/assessmentUser/queryByJudgeUser.do"), 
	/** 7 summary:修改：单条
	description:参数：单条数据对象AssessmentUserVO
	method:post
	params:需要配合Column函数使用
	*/ 
	update : new APIObject.postObject("/api/assessmentUser/update.do"), 
	/** 8 summary:查询-全部不分页
	description:参数：queryVO对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/assessmentUser/queryAll.do"), 
	/** 9 summary:指定打分人：（针对试用期员工考核中同事评分（转正表述）的考核维度）
	description:参数：考核维度主键ids，多个，打分人userIds，多个
	method:post
	params:需要配合Column函数使用
	*/ 
	appoint : new APIObject.postObject("/api/assessmentUser/appoint.do"), 
	/** 10 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/assessmentUser/delete.do"), 
	/** 11 summary:根据userId（部门负责人） ,月度，得到该用户（部门负责人）管理的部门员工总产值
	description:参数：userId（部门负责人），month(月度)
	method:post
	params:需要配合Column函数使用
	*/ 
	getDepSumChanZhi : new APIObject.postObject("/api/assessmentUser/getDepSumChanZhi.do"), 
	/** 12 summary:同意/不同意-当月考核表的所有打分项都同意/不同意
	description:描述：全部同意/不同意（对于当月考核表的所有打分项，被考核人都同意，就只需传年月就行）
	method:post
	params:需要配合Column函数使用
	*/ 
	agreeAll : new APIObject.postObject("/api/assessmentUser/agreeAll.do"), 
	/** 13 summary:查询-当前登录用户（被考核人自己的）
	description:参数：无参数，后台根据session自己获取
	method:post
	params:
	*/ 
	queryAllCurrentUser : new APIObject.postObject("/api/assessmentUser/queryAllCurrentUser.do"), 
	/** 14 summary:生成员工考核维度表
	description:参数：
	method:post
	params:需要配合Column函数使用
	*/ 
	generate : new APIObject.postObject("/api/assessmentUser/generate.do"), 
};
export default assessmentUser;