<!--
* @program: TYHOA2 
* @author: ly
* @component:1AuditSetting.vue
* @description: 
* @create: 2024-08-21 17:24
-->
<template>
    <div class="column-flex">
        <div class="search-bar" >
            <a-button  type="dashed"  @click="init">
                <template #icon><SyncOutlined /></template>
            </a-button>
            <a-select allow-clear v-model:value="department" style="width: 200px"
                      placeholder="选择部门进行查询"
                      @change="departmentSelect">
                <a-select-option v-for="item in departments"  :key="item.id">
                    {{item.fullName}}
                </a-select-option>
            </a-select>
            <a-input v-model:value="filter.userName" style="width:150px" placeholder="请输入用户名" />
            <div style="margin: 20px" v-if="department">部门负责人【{{leaderUserName}}】</div>
            <a-button :disabled="selectedRows.length === 0" type="dashed"  @click="groupSet">
                批量申请流程设定
            </a-button>
        </div>
        <div class="row-flex" >
            <div class="main-content30" style="min-width:400px">
                <SLTable :show-index="false" :loading="loading"
                         @selectChange="handleSelectChange"  :columns="tableColumns" :dataSource="source" >
                    <template #action="{record}">
                        <div class = "action-column">
                            <a-tooltip title="流程设定">
                                <a-button class="action-button" type="link" @click="editDetail1(record)">
                                    <EditFilled class="pointer"/>
                                </a-button>
                            </a-tooltip>
                            <a-tooltip  title="切换项目报账模式">
                                <a-button class="action-button" type="link" @click="changeBillType(record)">
                                    <retweet-outlined  class="pointer"/>
                                </a-button>
                            </a-tooltip>
                        </div>
                    </template>
                </SLTable>
            </div>
            <div class="main-content70">
                <Welcome />
            </div>
        </div>
    </div>
    <a-modal v-model:open="visible"
             destroyOnClose
             :footer="null"
             :title="null">
        <div v-if="!groupSetVisible" class="form-title">当前流程</div>
        <StepsViewer v-if="!groupSetVisible && currentSteps.length>0" :steps="currentSteps" />
        <div v-else>还未设定</div>
        <div class="form-title">流程设定</div>
        <BillStepsAdd :users="selectedRows"  />
    </a-modal>
</template>

<script>
    import StepsViewer from "../../../Equipment/AssetsSetting/StepsViewer";
    import SLTable from "../../../../components/table/SLTable";
    import {EditFilled,RetweetOutlined,SyncOutlined} from "@ant-design/icons-vue";
    import Column from "../../../../assets/tables/column";
    import ColumnType from "../../../../assets/tables/column_type";
    import api_sys_user_info from "../../../../assets/api/userInfo";
    import api_sys_department_relation from "../../../../assets/api/userDepartRelation";
    import processAudit from "../../../../assets/api/processAudit";
    import BillStepsAdd from "./BillStepsAdd";
    import Welcome from "../../../Other/Welcome";
    import {ArrayFilter} from "../../../../assets/utils/general";

    export default {
        name: "AuditSetting.vue",
        components: {
            StepsViewer,SLTable,BillStepsAdd,Welcome,
            EditFilled,RetweetOutlined,SyncOutlined
        },
        computed:{
            leaderUserName(){
                if(this.department){
                    return this.departments.find(item=>item.id === this.department).leaderUserName
                }
                else{
                    return ""
                }
            },
            source(){
                return ArrayFilter(this.dataSource,this.filter);
            }
        },
        data() {
            return {
                filter:{},
                departments:[],
                tableColumns:[
                    new Column("姓名","userName",ColumnType.String,true).setTableView(80),
                    new Column("报账模式","allItemFlag",ColumnType.Boolean,true)
                        .setTableView(100).setBoolTags(["部门项目报账","所有项目报账"]),
                    new Column("操作","actions","actions",false).setTableView(80)
                ],
                dataSource:[],
                loading:false,
                visible:false,
                selectedRows:[],
                stepType:"1", //类型：1：申领审批流程；2：归还审批流程
                currentSteps:[],
                groupSetVisible:false,
                department:undefined,
            }
        },
        methods:{
            init(){
                this.department = undefined;
                this.loading = true;
                api_sys_user_info.queryAll.urlPost({disableState:"1",loginState:"0"},res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data.map(item=>{
                            item.userId = item.id;
                            item.userName = item.name;
                            return item
                        });
                    }
                    this.loading = false;
                });
            },
            departmentSelect(){
                if(!this.department){
                    this.init()
                    return;
                }
                let param  ={
                    departmentId:this.department,
                    disableState:"1",
                    haveLevel:"0"
                };
                this.loading = true;
                this.dataSource=[];
                api_sys_department_relation.queryAll.urlPost(param,res=>{
                    this.loading = false;
                    if(res.data.flag && res.data.data.length>0){
                        this.dataSource = res.data.data;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            handleSelectChange(selectedRowKeys,selectedRows){
                this.selectedRows = selectedRows;
            },
            editDetail1(record){
                this.groupSetVisible = false;
                this.selectedRows = [record];
                this.visible = true;
                this.currentSteps=[];
                let data = {
                    id:record.userId,
                };
                processAudit.queryOne.urlPost(data,res=>{
                    if(res.data.flag){
                        this.currentSteps =  res.data.data.approvalUserName;
                    }
                });
            },
            groupSet(){
                this.groupSetVisible = true;
                this.stepType = "1";
                if(this.selectedRows.length>0){
                    this.visible = true;
                }
                else{
                    this.$message.error("请选择需要设定的用户");
                }
            },
            changeBillType(record){
                let data = {
                    id:record.userId?record.userId:record.id,
                    allItemFlag:!record.allItemFlag
                };
                api_sys_user_info.allItemFlag.urlPost(data,res=>{
                    if(res.data.flag){
                        record.allItemFlag = !record.allItemFlag;
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        },
        async created() {
            this.departments = JSON.parse(await this.$getItem("__departments"));
            this.init();
        }
    }
</script>

<style scoped>

</style>