/** 
* 自动生成文件，请勿修改 
* @name: 资产:设备申领模块
* @description: 设备申领模块-增删改查
* @create: 2024/9/29 15:41:49
* @swagger: 2.0
* 合计:8条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const deviceApply = { 
	/** 1 summary:申领申请-查询需要我审批且已到达我这里的申请-不分页
	description:参数：userId
	method:post
	params:需要配合Column函数使用
	*/ 
	queryMyApproval : new APIObject.postObject("/api/deviceApply/queryMyApproval.do"), 
	/** 2 summary:查询-分页
	description:参数：deviceApplyQueryVO,参数过多而封装的对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/deviceApply/queryPage.do"), 
	/** 3 summary:删除-单条（未被审核时才能删除）
	description:参数：一条申请对应多条审核流水，当所有流水状态state为0/1时，才能删除
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/deviceApply/delete.do"), 
	/** 4 summary:查询-单条
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/deviceApply/queryOne.do"), 
	/** 5 summary:申领申请-查询需要我审批且已到达我这里的申领申请-分页
	description:参数：userId
	method:post
	params:需要配合Column函数使用
	*/ 
	pageMyApproval : new APIObject.postObject("/api/deviceApply/pageMyApproval.do"), 
	/** 6 summary:删除-批量
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/deviceApply/batchDelete.do"), 
	/** 7 summary:新增/修改-单条（设备申领使用）
	description:注释：点击设备申领时，只需传设备id,用途描述即可，用户id根据当前登录用户后台获取
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/deviceApply/saveOrUpd.do"), 
	/** 8 summary:查询-所有数据
	description:参数：deviceApplyQueryVO,参数过多而封装的对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/deviceApply/queryAll.do"), 
};
export default deviceApply;