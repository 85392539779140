/** 
* 自动生成文件，请勿修改 
* @name: 报销:报账单模块
* @description: 报销-报账单模块-增删改查
* @create: 2024/9/29 15:41:49
* @swagger: 2.0
* 合计:17条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const record = { 
	/** 1 summary:报账单-分页查询
	description:参数：分页对象(传page、limit即可)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/record/queryPage.do"), 
	/** 2 summary:报账单-查询需要我审批且已到达我这里的账单-分页
	description:参数：userId
	method:post
	params:需要配合Column函数使用
	*/ 
	pageMyApproval : new APIObject.postObject("/api/record/pageMyApproval.do"), 
	/** 3 summary:报账单-报账金额统计(排除5个人的)
	description:参数：
	method:post
	params:需要配合Column函数使用
	*/ 
	querySumAmountCommon : new APIObject.postObject("/api/record/querySumAmountCommon.do"), 
	/** 4 summary:报账单-保存/修改
	description:参数：单条数据对象RecordVO（userId（可不填，通过request对象获取，后台填充）；submitState(保存时可不填，后端默认0未提交,若是提交时，填1);approvalState(保存时不填，后端默认0，修改时需填);entryState(保存时不填，后端默认0，修改时需填)）
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/record/saveOrUpd.do"), 
	/** 5 summary:报账单-撤销提交（在没有任何人审核情况下，可以撤销）
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	cancelSubmit : new APIObject.postObject("/api/record/cancelSubmit.do"), 
	/** 6 summary:报账单-批量删除
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/record/batchDelete.do"), 
	/** 7 summary:报账单-批量将账单解绑（绑定了pid的账单，发现绑错时解绑）
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	unBindPid : new APIObject.postObject("/api/record/unBindPid.do"), 
	/** 8 summary:报账单-单条数据查询
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/record/queryOne.do"), 
	/** 9 summary:报账单-分页查询（从现金账户扣钱的账单）
	description:参数：分页对象(传page、limit即可)
	method:post
	params:需要配合Column函数使用
	*/ 
	pageCashRecord : new APIObject.postObject("/api/record/pageCashRecord.do"), 
	/** 10 summary:报账单-单条删除
	description:参数：单条数据的主键id(报账单删除时，流水及审核流水都将删除，页面应做二次提示)
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/record/delete.do"), 
	/** 11 summary:报账单-批量将账单绑定到其他账单下
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchBindPid : new APIObject.postObject("/api/record/batchBindPid.do"), 
	/** 12 summary:报账单-所有数据查询
	description:参数：所有参数可有可无
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/record/queryAll.do"), 
	/** 13 summary:报账单-所有数据查询(排除5个人的)
	description:参数：所有参数可有可无
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAllCommon : new APIObject.postObject("/api/record/queryAllCommon.do"), 
	/** 14 summary:报账单-报账（私人入账的账单，标记是否挂账人已经向公司报销了）
	description:参数：报账单主键id，多个逗号分隔；报账状态reimburState（1：是,0:否）
	method:post
	params:需要配合Column函数使用
	*/ 
	recordReimbur : new APIObject.postObject("/api/record/recordReimbur.do"), 
	/** 15 summary:报账单-分页查询(排除5个人的)
	description:参数：分页对象(传page、limit即可)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPageCommon : new APIObject.postObject("/api/record/queryPageCommon.do"), 
	/** 16 summary:报账单-入账
	description:参数：报账单主键id；入账状态：0：未入账；1：对公入账；2：拒绝入账；3：撤销入账,4：私人入账
	method:post
	params:需要配合Column函数使用
	*/ 
	recordEntry : new APIObject.postObject("/api/record/recordEntry.do"), 
	/** 17 summary:报账单-查询需要我审批且已到达我这里的账单-不分页
	description:参数：userId
	method:post
	params:需要配合Column函数使用
	*/ 
	queryMyApproval : new APIObject.postObject("/api/record/queryMyApproval.do"), 
};
export default record;