
/**
 * @program: office_automation
 * @author: ly
 * @description: item_finance_income
 * 其他收入记录
 * @create: 2022-01-25 11:52
 **/
import Table from "@/assets/tables/table";
import Column from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type";

export default class item_finance_income extends Table{
    constructor() {
        super();
        this.name = "item_finance_income";
        this.CNName = "其他收入记录";
        this.columnArray = [
            new Column("收入类别","categoryId",ColumnType.Category,false).setTableView(80),
            new Column("收款方式","collectType",ColumnType.Enum,true).setTableView(80)
                .setEnum(
                    {value:"1",label: "现金",color:"green"},
                    {value:"2",label: "对公打款",color:"green"},
                ),
            new Column("收入日期","collectDate",ColumnType.Date,true).setTableView(80),
            new Column("账户名称","bankName",ColumnType.String,false).setTableView(100),
            new Column("办卡支行名称","subBank",ColumnType.String,false),
            new Column("入账账户","bankNum",ColumnType.Selector,false),
            new Column("金额（元）","collectAmount",ColumnType.Number,true).setTableView(80),
            new Column("所属项目","itemName",ColumnType.String).setTableView(100),
            new Column("说明","remarks",ColumnType.String,true).setTableView(100),
        ];
        this.actions = [new Column("操作","actions","actions",false).setTableView(80)];
    }
}
