/**
 * @program: office_automation
 * @author: ly
 * @description: item_invoice_record_payoff
 * 外协开票给公司的发票（公司付款的发票）
 * @create: 2021-12-15 19:06
 **/
import Table from "@/assets/tables/table";
import Column from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type";

export default class item_invoice_record_payoff extends Table {
    constructor() {
        super();
        this.name = "item_invoice_record_payoff";
        this.CNName = "发票信息";
        this.columnArray = [
            //new Column("发票标题", "invoiceTitle", ColumnType.String, true).setTableView(150),
            new Column("付款合同", "contractName", ColumnType.String, false).setTableView(80),
            new Column("开票金额", "invoiceAmount", ColumnType.Number, true).setTableView(80),
            new Column("已付款项", "payAmount", ColumnType.Number, false).setTableView(80).setGroup(1),
            new Column("付款状态", "payState", ColumnType.Enum, false).setGroup(1)
                .setDictEnum("payState"),
            new Column("开票日期", "invoiceTime", ColumnType.Date, true),
            new Column("发票代码", "invoiceCode", ColumnType.String, true),
            new Column("发票号码", "invoiceNumber", ColumnType.String, true).setTableView(100),
            new Column("发票类型", "invoiceType", ColumnType.Enum, true).setDefaultValue("1")
                .setDictEnum("invoiceType")
                .setTriggerOtherColumn("1", (formData, cols) => {
                    cols.filter(col => {
                        return col.dataIndex === "checkCode"
                    })[0]?.setVisible(true);
                }, (formData, cols) => {
                    cols.filter(col => {
                        return col.dataIndex === "checkCode"
                    })[0]?.setVisible(false);
                }),
            new Column("检验码", "checkCode", ColumnType.String, true),
            new Column("付款方", "partyId", ColumnType.Enum, false)
                .setDictEnum("companies").setGroup(1),
            new Column("收款方", "outSourceInfoDTO.name", ColumnType.String).setGroup(1),
            // new Column("收款方", "companyId", ColumnType.IdReplace, true).setGroup(1)
            //     .setIdReplaceObject("name", {api:api_item_outsourc_info.queryAll,tag:"queryText"}),
            new Column("项目", "itemInfo.itemName", ColumnType.String, false).setGroup(1),
            new Column("冲红", "flushRed", ColumnType.Boolean, true).setGroup(2)
                .setBoolTags(["未冲红", "已冲红"]).setDefaultValue(false),

        ];
        this.actions = [new Column("操作", "actions", "actions", false).setTableView(80)];
    }
}
