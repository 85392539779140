/** 
* 自动生成文件，请勿修改 
* @name: 项目:项目质检报告管理模块
* @description: 项目-项目质检报告管理模块-增删改查
* @create: 2024/9/29 15:41:49
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const qcReportApply = { 
	/** 1 summary:保存/修改-单个项目质检报告
	description:参数：单条数据对象QcReportApplyVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/qcReportApply/saveOrUpd.do"), 
	/** 2 summary:查询-所有数据
	description:参数：itemId,applyId,assessment,queryText
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/qcReportApply/queryAll.do"), 
	/** 3 summary:保存/修改-批量
	description:参数：List<QcReportApplyVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSaveOrUpd : new APIObject.postObject("/api/qcReportApply/batchSaveOrUpd.do"), 
	/** 4 summary:删除-批量
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/qcReportApply/batchDelete.do"), 
	/** 5 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/qcReportApply/delete.do"), 
	/** 6 summary:查询-单条数据
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/qcReportApply/queryOne.do"), 
	/** 7 summary:查询-分页
	description:参数：(pageBean分页参数)，itemId,applyId,assessment,queryText
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/qcReportApply/queryPage.do"), 
};
export default qcReportApply;