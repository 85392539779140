/**
 * @program: oa2.0
 * @author: ly
 * @description: FinanceComp
 * @create: 2023-08-25 09:33
 **/
import yearBalance from "@/page/Finance/FinancialManager/yearBalanceMange/yearBalance";

import BankManager from "@/page/Finance/FinancialManager/BankManager/BankManager";
import financeSuject from "@/page/Finance/FinancialManager/financeSujectMange/financeSuject";
/*import financeReport from "@/page/Finance/financialCount/financeReport";*/


import BillSetting from "@/page/Finance/BillManager/BIllSetting/BillSetting";

import billStatisticsByCategory from "@/page/Finance/BillManager/billStatistics/billStatisticsByCategory";
import billStatisticsByProject from "@/page/Finance/BillManager/billStatistics/billStatisticsByProject";
import billStatisticsByDepartment from "@/page/Finance/BillManager/billStatistics/billStatisticsByDepartment";

import MenuList from "@/page/Other/OAMenuDefaultPage/MenuList";
import BillEntry from "@/page/Finance/BillManager/BillEntry";
import DepartmentBillEntry from "@/page/Finance/BillManager/DepartmentBillEntry";
import BillManager from "@/page/Finance/BillManager/BillManager";
import DepartmentBillManager from "@/page/Finance/BillManager/DepartmentBillManager";
import BankFlowRecords from "@/page/Finance/FinancialManager/BankFlowRecords/BankFlowRecords";
import OtherPayApply from "@/page/Finance/FinancialManager/OtherPayApply/OtherPayApply";
import CardManager from "@/page/Finance/FinancialManager/BankManager/CardManager";
let FinanceComp = [
    {path: '', component: MenuList, name:"FinanceComp",cnName:"默认页面",children:()=>{return FinanceComp}},
    /** 财务 **/
    {path: 'BankManager',name:"BankManager",cnName:"资金账户设定",component: BankManager},
    {path: 'CardManager',name:"CardManager",cnName:"CardManager",component: CardManager,
    },
    {path: 'yearBalance', component: yearBalance, name:"yearBalance",cnName:"年初余额"},
    {path: 'financeSuject', component: financeSuject, name:"financeSuject",cnName:"财务科目管理"},
    {path: 'BankFlowRecords', component: BankFlowRecords, name:"BankFlowRecords",cnName:"账户收付款管理"},
   /* {path: 'financeReport', component: financeReport, name:"financeReport",cnName:"财务报表"},*/

    /** 账单 **/
    {path: 'BillEntry', component: BillEntry, name:"BillEntry",cnName:"入账管理"},
    {path: 'DepartmentBillEntry', component: DepartmentBillEntry, name:"DepartmentBillEntry",cnName:"部门入账管理"},
    {path: 'BillManager', component: BillManager, name:"BillManager",cnName:"账单管理"},
    {path: 'DepartmentBillManager', component: DepartmentBillManager, name:"DepartmentBillManager",cnName:"部门账单管理"},
    {path: 'BillSetting', component: BillSetting, name:"BillSetting",cnName:"报账相关设定"},
    {path: 'OtherPayApply', component: OtherPayApply, name:"OtherPayApply",cnName:"资金账户支付申请"},



    {path: 'billStatisticsByCategory', component: billStatisticsByCategory, name:"billStatisticsByCategory",cnName:"按报账类别统计"},
    {path: 'billStatisticsByProject', component: billStatisticsByProject, name:"billStatisticsByProject",cnName:"项目消耗统计"},
    {path: 'billStatisticsByDepartment', component: billStatisticsByDepartment, name:"billStatisticsByDepartment",cnName:"部门报账统计"},

]

export default FinanceComp
