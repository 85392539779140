<!--
* @program: TYHOA2 
* @author: ly
* @component:ProjectArchive 
* @description: 项目归档
* @create: 2024-10-09 20:38
-->
<template>
    <div class="column-flex" >
        <div style="display: flex;margin: 0 8px">
            <a-button  type="dashed"  @click="init">
                <template #icon><SyncOutlined /></template>
            </a-button>
            <a-button type="dashed" v-if="!viewModel" @click="addData">
                <template #icon><PlusOutlined /></template>
                添加《成果资料移交清单》
            </a-button>
        </div>
        <MForm  title="归档状态" @save="saveData"
                   :labelSpan="3" :row-size="3" :form-data="mFormData" :form-columns="formColumns" :view-model="viewModel"/>
        <a-divider />
        <a-spin :spinning="loading" tip="正在查询数据库...">
            <div style="width: 100%;overflow: auto;display: flex;flex-wrap: wrap;" v-if="dataSource.length>0">
                <a-card hoverable v-for="(item,idx) in dataSource" :key="idx" style="width: 400px">
                    <template #title>
                    <span class="card-title">
                        {{item.docName}}
                    </span>
                    </template>
                    <template #extra>
                        <a-popover placement="bottom" trigger="hover" >
                            <template #content>
                                <div v-if="item.approveState !=='1' && !viewModel" class="card-edit">
                                    <span @click="editDetail(item)">修改</span>
                                </div>
                                <div v-if="item.approveState !=='1' && !viewModel" class="card-edit">
                                    <a-popconfirm
                                            @confirm="deleteData(idx,item)"
                                            cancel-text="否"
                                            class="action-button"
                                            ok-text="是"
                                            title="确定删除吗?">
                                        <span>删除</span>
                                    </a-popconfirm>
                                </div>
                                <div v-if="item.approveState ==='1'">已通过审核无法修改</div>
                            </template>
                            <a-button style="padding: 0 5px" type="link">
                                <UnorderedListOutlined style="font-size: 22px;"/>
                            </a-button>
                        </a-popover>
                    </template>
                    <LForm :form-columns="cols2.filter(it=>it.dataIndex !== 'docName')" :form-data="item" :view-model="true" />
                </a-card>
            </div>
            <a-result status="404" title="暂无成果资料移交清单" v-else/>
        </a-spin>
    </div>
    <a-modal
            width="600px"
            v-model:open="modalVisible"
            :footer="null" title="文件上传"
            destroyOnClose
            :maskClosable="vModel">
        <LForm v-if="mFormData.archiveCode && mFormData.archiveReceiveTime" :viewModel="vModel" :form-columns="cols2" :form-data="formData" >
            <template #action="{record}">
                <a-button  type="primary"  @click="saveFile(record)">
                    提交
                </a-button>
            </template>
        </LForm>
        <div v-else class="form-value" style="color: red">还未录入归档信息</div>
    </a-modal>
</template>

<script>
    import item_info from "../../TableStruct/item_info";
    import MForm from "../../../components/form/MForm";
    import api_item_info from "../../../assets/api/itemInfo";
    import {PlusOutlined, SyncOutlined, UnorderedListOutlined} from "@ant-design/icons-vue";
    import LForm from "../../../components/form/LForm";
    import Column from "../../../assets/tables/column";
    import ColumnType from "../../../assets/tables/column_type";
    import ItemDoc from "../../../assets/api/ItemDoc";


    export default {
        name: "ProjectArchive",
        inject:["pProjectId"],
        props:["viewModel"],
        emits:["update"],
        components: {
            MForm,PlusOutlined, SyncOutlined,UnorderedListOutlined,
            LForm
        },
        data() {
            return {
                formColumns: new item_info().getFormColumns().filter(item => item.group === 1),
                mFormData:{},
                modalVisible:false,
                loading:false,
                formData:null,//提供Form的formData
                cols2:[
                    new Column("文件名称","docName",ColumnType.String),
                    new Column("文件描述","describe",ColumnType.String,true),
                    new Column("扫描附件","docFile",ColumnType.PreViewFile,true),
                    new Column("审核状态","approveState",ColumnType.Enum,false)
                        .setDictEnum("item_progress_apply.approveState"),
                ],
                dataSource:[],//提供table的DataSource
                vModel:false,
                noticeMark:"0",

            }
        },
        methods: {
            init() {
                if (this.pProjectId) {
                    api_item_info.queryOne.urlPost({id: this.pProjectId}, res => {
                        if (res.data.flag) {
                            this.mFormData = res.data.data;
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    });
                    this.dataSource = [];
                    this.loading = true;
                    ItemDoc.queryAll.post({"itemId": this.pProjectId},res=>{
                        this.loading = false;
                        if(res.data.flag){
                            this.dataSource = res.data.data.filter(item=>item.docName === '成果资料移交清单');
                        }
                        else{
                            this.dataSource = []
                        }
                    })
                }
            },
            saveData(record,viewModel) {
                api_item_info.saveOrUpd.post(record, res => {
                    if (res.data.flag) {
                        this.mFormData = record;
                        this.$emit("update",record);
                        this.$message.success(res.data.msg);
                        viewModel(true);
                    } else {
                        this.$message.error(res.data.msg)
                    }
                });
            },
            showDetail(record){
                this.formData = record;
                this.modalVisible = true;
                this.vModel = true;
            },
            editDetail(record){
                this.formData = record;
                this.modalVisible = true;
                this.vModel = false;
            },
            deleteData(index,record){
                let data={id:record.id};
                ItemDoc.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.dataSource.splice(index,1);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveFile(record){
                record.itemId = this.pProjectId;
                if(!record.id){
                    record.noticeMark = "0";
                    record.docName = "成果资料移交清单";
                }
                ItemDoc.saveOrUpd.post(record,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.modalVisible = false;
                        this.init()
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            addData(){
                this.formData = {
                    docName:"成果资料移交清单",
                    describe:"",
                    docFile:"",
                };
                this.modalVisible = true;
                this.vModel = false;
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>