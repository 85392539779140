<!--
* @program: TYHOA2 
* @author: ly
* @component:ProjectOutcome 
* @description: 
* @create: 2024-07-26 14:11
-->
<template>
    <div style="width: 100%;height: 100% ;display: flex">
        <div class="main-content50">
            <ProjectInvoicePayOff  ref="payoff" @update="handleUpdate" :record="record" :bank-cards="bankCards"/>
        </div>
        <div class="main-content50">
            <ProjectOutcomeRecord  ref="records" :record="record" :bank-cards="bankCards"/>
        </div>
    </div>
</template>

<script>
    import ProjectOutcomeRecord from "./ProjectOutcomeRecord";
    import ProjectInvoicePayOff from "./ProjectInvoicePayOff";
    import bankBalance from "../../../assets/api/bankBalance";
    export default {
        name: "ProjectOutcome",
        props:["record"],
        components:{
            ProjectOutcomeRecord,ProjectInvoicePayOff
        },
        data(){
            return{
                bankCards:[]
            }
        },
        methods:{
            handleUpdate(){
                this.$refs.records.init()
            }
        },
        created() {
            bankBalance.queryAll.post({},res=>{
                if(res.data.flag){
                    this.bankCards = res.data.data;
                }
                else{
                    this.bankCards = [];
                }
            });
        }
    }
</script>

<style scoped>

</style>