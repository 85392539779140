import MyAssetsApply from "@/page/PersonalCenter/MyAssetsManager/MyAssetsApply";
import MyAssetsReturn from "@/page/PersonalCenter/MyAssetsManager/MyAssetsReturn";
import WorkDiary from "@/page/PersonalCenter/WorkCenter/WorksDiary/WorkDiary";
import WorkTask from "@/page/PersonalCenter/WorkCenter/WorkTask/WorkTask";
import MonthlyDoAssessment from "@/page/PersonalCenter/PersonalAssessment/Monthly/DoAssessment";
import MonthlyMyAssessment from "@/page/PersonalCenter/PersonalAssessment/Monthly/MyAssessment";
import DoYAssessment from "@/page/PersonalCenter/PersonalAssessment/Yearly/DoYAssessment";
import MyYAssessment from "@/page/PersonalCenter/PersonalAssessment/Yearly/MyYAssessment";
import ProjectInvoicePayAudit from "@/page/PersonalCenter/MyAudit/ProjectInvoicePayAudit";
import OtherPayAudit from "@/page/PersonalCenter/MyAudit/OtherPayAudit";
import QCWorkTask from "@/page/PersonalCenter/WorkCenter/QCWorkTask/QCWorkTask";
import QCWorkDiary from "@/page/PersonalCenter/WorkCenter/WorksDiary/QCWorkDiary";


let PersonalCenterComp = [
    {path: '', component: ()=>import("@/page/Other/OAMenuDefaultPage/MenuList") , name:"PersonalCenterComp",children:()=>{return PersonalCenterComp}},
    {path: 'MyAssetsManager', name:"MyAssetsManager",cnName:"我的设备管理",
        component: ()=>import("@/page/PersonalCenter/MyAssetsManager/MyAssetsManager"),
        children:[
            {path: 'MyAssetsApply', name:"MyAssetsApply",cnName:"设备申请",
                meta:{content:"经过审核后，可向办公室申领设备",checkAuth:"parentAuth"},
                component: MyAssetsApply,
            },
            {path: 'MyAssetsReturn', name:"MyAssetsReturn",cnName:"设备归还",
                meta:{content:"归还设备，禁止私自转交于他人",checkAuth:"parentAuth"},
                component: MyAssetsReturn,
            }]
        },
    {path: 'WorkCenter', component: ()=>import("@/page/PersonalCenter/WorkCenter/WorkCenter"),
        name:"WorkCenter",cnName:"工作中心",
        children:[
            {path: 'WorkDiary', name:"WorkDiary",cnName:"工作日志",
                meta:{content:"项目工作、其他工作内容",checkAuth:"parentAuth"},
                component: WorkDiary,
            },
            {path: 'WorkTask', name:"WorkTask",cnName:"生产任务",
                meta:{content:"项目工作任务安排",checkAuth:"parentAuth"},
                component: WorkTask,
            },
            {path: 'QCWorkDiary', name:"QCWorkDiary",cnName:"质检日志",
                meta:{content:"质检工作、其他工作内容",checkAuth:"parentAuth"},
                component: QCWorkDiary,
            },
            {path: 'QCWorkTask', name:"QCWorkTask",cnName:"质检任务",
                meta:{content:"质检任务工作安排",checkAuth:"parentAuth"},
                component: QCWorkTask,
            },
        ]
    },
    {path: 'MyBill', component: ()=>import("@/page/PersonalCenter/MyBill/MyBill"),
        name:"MyBill",cnName:"我的报账"},
    {path: 'BillAudit', component: ()=>import("@/page/PersonalCenter/MyAudit/BillAudit"),
        name:"BillAudit",cnName:"我的报账审核"},
    {path: 'PersonalAssessment', name:"PersonalAssessment",cnName:"考核中心",
        component: ()=>import("@/page/PersonalCenter/PersonalAssessment/PersonalAssessment"),
        children:[
            {path: 'MonthlyMyAssessment', name:"MonthlyMyAssessment",cnName:"我的月度考核",
                meta:{content:"查看我的月度考核结果",checkAuth:"parentAuth"},
                component: MonthlyMyAssessment,
            },
            {path: 'MyYAssessment', name:"MyYAssessment",cnName:"我的年度考核",
                meta:{content:"查看我的年度考核结果",checkAuth:"parentAuth"},
                component: MyYAssessment,
            },
            {path: 'MonthlyDoAssessment', name:"MonthlyDoAssessment",cnName:"月度考核评分",
                meta:{content:"需要我打分的月度考核",checkAuth:"parentAuth"},
                component: MonthlyDoAssessment,
            },
            {path: 'DoYAssessment', name:"DoYAssessment",cnName:"年度考核评分",
                meta:{content:"需要我打分的年度考核",checkAuth:"parentAuth"},
                component: DoYAssessment,
            },
        ]
    },
    {path: 'Home', component: ()=>import("@/page/Other/HomePage/HomePage"),
        name:"Home",cnName:"首页"},
    {path: 'ProjectInvoicePayAudit', component: ProjectInvoicePayAudit,
        name:"ProjectInvoicePayAudit",cnName:"外协支付审核"},
    {path: 'OtherPayAudit', component: OtherPayAudit,
        name:"OtherPayAudit",cnName:"其他支付审核"},
];


export default PersonalCenterComp