<!--
* @program: office_automation2.0
* @author: ly
* @component:LTable
* @description:
* @create: 2022-10-31 10:33
-->
<template>
    <Transition name="fade">
        <div style="position: relative;width: 100%;height: 100%;">
            <div class="form-title" style="margin: 0" v-if="title">{{title}}</div>
            <a-table
                    :columns="cols"
                    :custom-row="customRow"
                    :data-source="dataSource"
                    :loading="loading"
                    :pagination="pagination"
                    childrenColumnName="xchildren"
                    :rowClassName="rowClass"
                    :rowKey="rowKey"
                    :scroll="{y:offsetHeight}"
                    @resizeColumn="handleResizeColumn"
                    :rowExpandable="(record)=>{return record && record.children && record.children.length>0}"
                    :showExpandColumn="false"
                    v-model:expandedRowKeys="expandedRowKeys"
                    size="small">
                <template #headerCell="{ column }">
                    <div class="action-column">
                        {{column.title}}
                    </div>
                </template>
                <template #bodyCell="{index, record , column}">
                    <template v-if="column.type === 'actions'">
                        <slot :column="column" :index="index" :record="record" name="action"/>
                    </template>
                    <template v-else>
                        <DataPresent :column="column" :record="record" :index="index+1+passedNum" @expanded="handleExpanded"
                                     :view-model="record.viewModel !== false"/>
                    </template>
                </template>
                <template #footer>
                    <div v-if="download && dataSource && dataSource.length>0" @click="downLoadExcel" class="table-download-btn">
                        Excel下载
                    </div>
                </template>
                <template #expandedRowRender="{ record }">
                    <slot :record="record" :children="record.children" name="expandedRow"/>
                </template>
            </a-table>
        </div>
    </Transition>
</template>

<script>
    import DataPresent from "../dataPresentType/DataPresent";
    import ExportJsonExcel from "js-export-excel";
    import columnType from "@/assets/tables/column_type";
    import Column from "@/assets/tables/column";
    import dayjs from "dayjs"

    export default {
        name: "LTable",
        components: {
            DataPresent
        },
        emits: ["click"],
        provide: {
            holder: "table",
        },
        props: {
            title:{
                required: false,
                default:null
            },
            columns: {
                required: true,
                default:[]
            }, //提供字段集
            dataSource: {
                required: true,
                default:[]
            },//提供table的DataSource

            loading: {required: false},//table的loading状态
            showIndex: {
                required: false,
                default: true
            },
            pageSize: {//每页显示条数
                required: false,
                default: 0
            },
            showAll: {required: false},//是否显示全部字段 默认为false
            rowKey: {
                required: false,
                default: "id"
            }, //rowKey
            download: {
                required: false,
                default: true
            }, //是否显示下载为excel按钮
            fixed:{
                required: false
            },
            cellHeight:{// 行高
                required: false,
                default: 42
            }
        },
        watch:{
            dataSource(){
                this.offSize()
            }
        },
        computed: {
            cols() {
                let arr = [...this.columns];
                if (this.showIndex) {
                    arr.unshift(
                        new Column("序号", "_index", columnType.Index).setTableView(50)
                    );
                }
                if(this.fixed === true){
                    arr[0].setFixed("left").setResizable(false);
                    arr[arr.length - 1].setFixed("right").setResizable(false);
                }
                return arr.filter(column => {
                    return column.tableView
                });
            },
            passedNum() {
                return this.pagination.pageSize * (this.pagination.current - 1);
            }
        },
        data() {
            return {
                currentIndex: null,
                pagination: {
                    current: 1,
                    size: "small",
                    hideOnSinglePage: true,
                    defaultPageSize: 20,
                    showSizeChanger: false,
                    pageSizeOptions: ['10', '15', '20'],
                    onChange: (page) => {
                        this.currentIndex = null;
                        let data = { //结构与接口规定有关
                            pageNum: page,
                            pageSize: 20,
                        };
                        this.pagination.current = page;
                        this.$emit("pageChange", data);
                    },
                    showTotal: (total, range) => {
                        return `共${total}项(${range[0]}-${range[1]})`
                    }
                },
                offsetWidth: 0,
                offsetHeight: 0,
                expandedRowKeys:[]
            }
        },
        methods: {
            setPage(total, current) {
                this.pagination.total = total;
                this.pagination.current = current ? current : 1;
            },
            setTotalSize(num) {
                this.pagination.total = num;
            },
            setCurrentPage(num) {
                this.pagination.current = num;
            },
            rowClass(record,index) {
                let name = "";
                if(index%2 === 0){
                    name = "even-row"
                }
                if (index === this.currentIndex) {
                    name = "highlight-row"
                }
                return name
            },
            customRow(record, index) {
                let _this = this;
                return {
                    onClick() {
                        if (_this.currentIndex !== index) {
                            _this.currentIndex = index;
                            _this.$emit("click", record);
                        } else {
                            _this.currentIndex = -1;
                        }
                    },
                    onDblclick() {
                        _this.currentIndex = null;
                    }
                }
            },
            handleResizeColumn(w, col) {
                col.width = w;
            },
            downLoadExcel() {
                if(this.dataSource.length === 0){
                    return
                }
                let sheetFilter = [];
                let sheetHeader = [];
                let columnWidths = [];
                for (let i in this.cols) {
                    sheetFilter.push(this.cols[i].dataIndex);
                    sheetHeader.push(this.cols[i].title);
                    columnWidths.push("8");
                }
                let sheetData = JSON.parse(JSON.stringify(this.dataSource));
                sheetData.forEach((item, index) => {
                    item._index = index + 1;
                    for (let n in this.cols) {
                        let {type, dataIndex, boolTags, enumTags, enums, idReplaceObject} = this.cols[n];
                        switch (type) {
                            case columnType.Date: {
                                item[dataIndex] = new Date(item[dataIndex]).toLocaleDateString();
                                break;
                            }
                            case columnType.Boolean : {
                                if (boolTags) {
                                    item[dataIndex] = item[dataIndex] ? boolTags[1] : boolTags[0];
                                } else {
                                    item[dataIndex] = item[dataIndex] ? "是" : "否";
                                }
                                break;
                            }
                            case columnType.Enum: {
                                if (enumTags) {
                                    let idx = enums.indexOf(item[dataIndex]);
                                    item[dataIndex] = enumTags[idx] ? enumTags[idx] : "无";
                                }
                                break;
                            }
                            case columnType.IdReplace: {
                                item[dataIndex] = item[idReplaceObject.replaceSegment] ? item[idReplaceObject.replaceSegment] : "无";
                                break;
                            }
                            case columnType.Month: {
                                item[dataIndex] = dayjs(item[dataIndex]).format("YYYY-MM");
                                break;
                            }
                        }
                    }
                });
                let datas = [
                    {
                        sheetFilter: sheetFilter,
                        sheetHeader: sheetHeader,
                        columnWidths: columnWidths,
                        sheetName: "sheet",
                        sheetData: sheetData
                    }
                ];
                let option = {};
                option.fileName = new Date().toLocaleDateString();//文件名
                option.datas = datas;
                new ExportJsonExcel(option).saveExcel();
            },
            handleClick(record) {
                this.$emit("click",record);
            },
            offSize() {
                this.offsetWidth = 0;
                this.offsetHeight = 0;
                if (this.$el && this.dataSource && this.dataSource.length>0) {
                    let myHeight = this.$el.offsetHeight;
                    let myWidth = this.$el.offsetWidth;
                    let pHeight = this.$el.parentElement.offsetHeight;
                    let pWidth = this.$el.parentElement.offsetWidth;
                    let children = this.$el.parentElement.childNodes;
                    let cHeight = 0;
                    let cWidth = 0;
                    for(let child of children){
                        cHeight += child.offsetHeight;
                        cWidth += child.offsetWidth
                    }
                    // 除本元素之外的其他子元素高度 = 所有子元素高度 - 本元素高度
                    // 本元素应该高度 = 父窗口高度 - 除本元素之外的其他子元素高度
                    let offsetHeight = pHeight- ( cHeight - myHeight);
                    let offsetWidth = pWidth- ( cWidth - myWidth);
                    this.offsetWidth = offsetWidth - 20 ;
                    this.offsetHeight = offsetHeight - 60; //减去footer高度
                    if(this.title){//title高50
                        this.offsetHeight -= 44;
                    }
                }
                this.offsetWidth = this.offsetWidth > 300 ? this.offsetWidth : 300;
                this.offsetHeight = this.offsetHeight > 300 ? this.offsetHeight : 300;
                //动态计算一页应该展示几个 cellHeight一行的高度
                if(this.dataSource && this.dataSource.length>0){
                    if(this.pageSize){
                        this.offsetHeight = this.pageSize * this.cellHeight;
                        this.pagination.pageSize = this.pageSize;
                    }
                    else{
                        this.pagination.pageSize = Math.trunc(this.offsetHeight / this.cellHeight);
                    }

                }
            },
            handleExpanded(key){
                let find = this.expandedRowKeys.find(item =>item === key);
                if(find){
                    this.expandedRowKeys = this.expandedRowKeys.filter(item => item !== key)
                }
                else {
                    this.expandedRowKeys.push(key);
                }
            }
        },
        mounted() {
            this.offSize()
        }
    }
</script>

<style scoped>

</style>
