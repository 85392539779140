<!--
* @program: TYHOA2 
* @author: ly
* @component:DepartmentAssets 
* @description: 部门设备一览
* @create: 2024-10-09 11:22
-->
<template>
    <div class="column-flex">
        <div class="search-bar">
            <a-button @click="init" type="dashed">
                <template #icon>
                    <SyncOutlined/>
                </template>
            </a-button>
            <div  style="margin-left:15px;">类别：</div>
            <assetsCategoryTree :category-group="categoryGroup" @change="init"  v-model:value="queryOptions.categoryId"/>
            <a-select v-model:value="queryOptions.departId" style="width: 200px"
                      class="title-divider"
                      placeholder="选择部门进行查询"
                      @change="departmentSelect">
                <a-select-option v-for="item in myDepartments"  :key="item.id">
                    {{item.fullName}}
                </a-select-option>
            </a-select>
            <a-input  placeholder="设备名称" style="width: 150px" v-model:value="filter.name" />
            <a-input  placeholder="设备编码" style="width: 150px" v-model:value="filter.code" />
            <a-input  placeholder="使用人" style="width: 100px" v-model:value="filter.currentUseUserName" />
        </div>
        <div style="width: 100%;height:100%;">
            <LTable :columns="tableColumns" :dataSource="source" :download="true" :loading="loading">
                <template #action="{record}">
                    <div class="action-column">
                        <a-button v-if="!record.mainId" @click="showDetail(record)" class="action-button" type="link">
                            <SearchOutlined class="pointer"/>
                        </a-button>
                        <a-tooltip v-if="!record.mainId" title="借还历史">
                            <a-button @click="deviceHistory(record)" class="action-button" type="link">
                                <ClockCircleOutlined class="pointer"/>
                            </a-button>
                        </a-tooltip>
                    </div>
                </template>
                <template #expandedRow="{children}">
                    <div class="action-column">
                        <span>本设备为主设备，包含附属设备为:</span>
                        <div style="margin-left: 10px" v-for="(child,index) in children" :key="child.id">
                            【{{index+1}}.{{child.name}}({{child.code}})】
                            <a-popconfirm
                                    @confirm="unbinding(child)"
                                    cancel-text="否"
                                    class="action-button"
                                    ok-text="是"
                                    title="确定解除绑定吗?">
                                <a-tooltip title="解除绑定">
                                    <a-button class="action-button" type="link">
                                        <LinkOutlined class="pointer" style="color: red"/>
                                    </a-button>
                                </a-tooltip>
                            </a-popconfirm>
                        </div>
                    </div>
                </template>
            </LTable>
        </div>
        <a-drawer
                placement="bottom"
                height="50%"
                :closable="false"
                :visible="visible"
                :get-container="false"
                :style="{ position: 'absolute' }"
                @close="visible =false"
                destroy-on-close>
            <div class="search-bar">
                <div style="margin:0 5px">设备名称：</div>
                <a-input placeholder="设备名称" style="width: 150px" v-model:value="filter2.name" />
                <div style="margin:0 5px">设备编码：</div>
                <a-input  placeholder="设备编码" style="width: 150px" v-model:value="filter2.code" />
                <assetsCategoryTree :category-group="categoryGroup" @change="init2"  v-model:value="queryOptions2.categoryId"/>
                <QueryFilter :filter="queryFilter" @change="init2" v-model:value="queryOptions2"/>
                <a-popconfirm
                        @confirm="saveBinding"
                        cancel-text="否"
                        ok-text="是"
                        title="确定绑定吗?">
                    <a-button type="primary">
                        绑定
                    </a-button>
                </a-popconfirm>
            </div>
            <SLTable :columns="tableColumns" :dataSource="source2" :loading="loading2" @selectChange="handleSelectChange" v-if="visible"/>
        </a-drawer>
    </div>
    <a-modal :footer="null"
             :maskClosable="viewModel"
             :title="title"
             destroyOnClose
             v-model:open="modalVisible">
        <LForm :label-span="8" :form-data="currentRecord" :formColumns="formColumns" :view-model="viewModel">
            <template #action="{record}">
                <a-button @click="saveData(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
    <a-modal :footer="null"
             :width="800"
             destroy-on-close
             title="借还历史"
             v-model:open="historyVisible">
        <LTable :download="false" title="申请历史" :columns="cols" :dataSource="applyDS" :loading="historyLoading" >
            <template #action="{record}">
                <div class="action-column">
                    <a-button  @click="applyHistory(record)" class="action-button" type="link">
                        审核情况
                    </a-button>
                </div>
            </template>
        </LTable>
        <LTable :download="false" title="归还历史" :columns="cols" :dataSource="backDS" :loading="historyLoading" >
            <template #action="{record}">
                <div class="action-column">
                    <a-button  @click="applyHistory(record)" class="action-button" type="link">
                        审核情况
                    </a-button>
                </div>
            </template>
        </LTable>
        <div v-if="steps.length>0" class="form-title">审核情况</div>
        <AuditSteps v-if="steps.length>0" :steps="steps" />
    </a-modal>

    <a-modal :footer="null"
             title="更改设备用途"
             destroyOnClose
             v-model:open="sendVisible">
        <a-radio-group v-model:value="assetPermanentFlag">
            <a-radio v-for="item in queryFilter.permanentFlag.options" :value="item.value" :key="item.value">{{item.label}}</a-radio>
        </a-radio-group>
        <a-form-item label="设备说明" >
            <a-textarea :auto-size="{ minRows: 2, maxRows: 6 }" v-model:value="param"/>
        </a-form-item>
        <a-button @click="flyAssets"  type="primary">
            提交
        </a-button>
    </a-modal>
    <a-modal :footer="null"
             title="更改设备健康状态"
             destroyOnClose
             v-model:open="alterVisible">
        <a-radio-group v-model:value="assetState">
            <a-radio v-for="item in queryFilter.state.options" :value="item.value" :key="item.value">{{item.label}}</a-radio>
        </a-radio-group>
        <a-form-item label="设备说明" >
            <a-textarea :auto-size="{ minRows: 2, maxRows: 6 }" v-model:value="param"/>
        </a-form-item>
        <a-button @click="alterState"  type="primary">
            提交
        </a-button>
    </a-modal>
</template>

<script>
    import AssetsManager from "../../Equipment/AssetsManager/AssetsManager";
    import api_device from "../../../assets/api/device";
    export default {
        name: "DepartmentAssets",
        mixins:[AssetsManager],
        data() {
            return {
                queryOptions: {
                    state: "1", // 启用状态：0：停用，1：启用
                    useState: "1", // useState 设备借出状态：0：未借出（可以借用的），1：已借出（不能借用的）
                    mainFlag: "1", //是否主设备 0：显示全部；1：只显示主设备
                    categoryId:undefined,
                    permanentFlag: "0",
                    fixedAsset:"0",//是否为固定资产 0：不是  1：是
                    departId:null,
                },
                myDepartments:[]
            }
        },
        methods:{
            async init() {
                this.myDepartments = JSON.parse(await this.$getItem("__myDepartments"));
                this.queryOptions.departId = this.myDepartments[0].id;
                this.loading = true;
                api_device.queryAll.post(this.queryOptions, res => {
                    if (res.data.flag) {
                        this.dataSource = res.data.data.sort((a,b)=>{
                            return b.createTime - a.createTime
                        });
                    } else {
                        this.dataSource = [];
                    }
                    this.loading = false;
                });
            },
        }
    }
</script>

<style scoped>

</style>