<!--
* @program: office_automation
* @author: ly
* @component:FromOtherBank
* @description: 其他账户转入的情况
* @create: 2022-02-22 10:02
-->
<template>
    <div class="center-flex">
        <span class="statistic-label"> 合计：{{summary}} 元</span>
        <MonthMoneySum :start-date="record.balanceDate" :records="argument.dataSource" date-seg="payDate" sum-seg="payAmount" />
    </div>
    <div style="height:600px">
    <LTable :data-source="argument.dataSource" :columns="argument.tableColumns" :page-size="pageSize">
        <template #action="{record}">
            <div class="action-column">
                <a-button class="action-button" type="link" @click="showDetail(record)">
                    <Search class="pointer"/>
                </a-button>
            </div>
        </template>
    </LTable>
    </div>
    <a-modal v-model:open="visible"
             destroyOnClose
             :footer="null"
             :title="null">
        <LForm :form-data="formArg.formData" :form-columns="formArg.formColumns" :view-model="viewModel" >

        </LForm>
    </a-modal>
</template>

<script>
    import Icons from "@/page/common/Icons";
    import OtherOutflow from "@/page/Finance/FinancialManager/BankManager/OtherOutflow";
    import otherPay from "@/assets/api/otherPay";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type";
    import LTable from "@/components/table/LTable";
    import LForm from "@/components/form/LForm";
    import MonthMoneySum from "@/page/Finance/FinancialManager/BankManager/MonthMoneySum";
    export default {
        name: "FromOtherBank",
        mixins:[OtherOutflow,Icons],
        components: {
          LTable,LForm,MonthMoneySum
        },
        methods:{
            init(){
                this.visible = false;
                this.argument.loading = true;
                this.argument.dataSource = [];
                let data = {
                    "approvalState": "1",
                    "receiveBankId": this.record.id,
                };
                otherPay.queryAll.post(data,res=>{
                    this.argument.loading = false;
                    if(res.data.flag){
                        this.argument.dataSource = res.data.data;
                        this.$emit("update:value",{value:this.summary,loading:false});
                    }
                    else{
                        this.$emit("update:value",{value:0,loading:false});
                    }
                })
            },
        },
        created() {
            this.argument.tableColumns = [
               /* new Column("序号","_index",ColumnType.Index,false).setTableView(60),*/
                new Column("支付类型","type",ColumnType.Enum).setTableView(150)
                    .setDictEnum("item_other_pay.type"),
                /*new Column("费用类别","categoryId",ColumnType.Category,true),*/
              new Column("费用类别","categoryId",ColumnType.String,true),
                new Column("支付金额","payAmount",ColumnType.Number,true).setDefaultValue(0).setTableView(100),
                new Column("支付日期","payDate",ColumnType.Date,true).setTableView(100),
                new Column("支付账户","payBankId",ColumnType.Selector,true).setTableView(150)
                    .setSelectorObject("payBankNum",[]),
                new Column("操作","actions","actions",false).setTableView(80)
            ];
        }

    }
</script>

<style scoped>

</style>
