<!--
* @program: TYHOA2 
* @author: ly
* @component:ProjectContractInfo 
* @description: 
* @create: 2024-07-23 17:50
-->
<template>
    <div style="display: flex;margin: 0 8px">
        <a-button v-if="!viewModel" @click="addData" type="dashed">
            <template #icon>
                <PlusOutlined/>
            </template>
            添加合同
        </a-button>
        <a-button  type="dashed"  @click="init">
            <template #icon><SyncOutlined /></template>
        </a-button>
    </div>
    <a-spin :spinning="loading" tip="正在查询数据库...">
        <div style="width: 100%;overflow: auto;display: flex;flex-wrap: wrap;" v-if="source1.length>0">
            <a-card hoverable :key="idx" style="width: 400px;margin:5px" v-for="(item,idx) in source1" >
                <template #title>
                    <span class="card-title">
                        {{getFileType(item.type)}}
                        <span v-if="['1','2','4','5'].indexOf(item.type) !== -1">（{{item.number}}）</span>
                    </span>
                </template>
                <template #extra>
                    <a-popover placement="bottom" trigger="hover" >
                        <template #content>
                            <div class="card-edit">
                                <span @click="showDetail(item)" title="查看详情" v-if="!item.visible">详情</span>
                            </div>
                            <div class="card-edit">
                                <span v-if="!viewModel" @click="editDetail(item)">修改</span>
                            </div>
                            <div class="card-edit">
                                <a-popconfirm
                                        @confirm="deleteData(item)"
                                        cancel-text="否"
                                        class="action-button"
                                        ok-text="是"
                                        title="确定删除吗?">
                                    <span>删除</span>
                                </a-popconfirm>
                            </div>
                        </template>
                        <a-button style="padding: 0 5px" type="link">
                            <UnorderedListOutlined style="font-size: 22px;"/>
                        </a-button>
                    </a-popover>
                </template>
                <VForm :form-columns="cardColumns" :form-data="item" :view-model="true"/>
            </a-card>
        </div>
        <div style="width: 100%;overflow: auto;display: flex;flex-wrap: wrap;" v-if="source2.length>0">
            <a-card hoverable :key="idx" style="width: 400px;margin:5px" v-for="(item,idx) in source2">
                <template #title>
                    <span class="card-title">
                        {{getFileType(item.type)}}
                        <span v-if="item.type ==='1' || item.type ==='4'||item.type ==='7'">（{{item.number}}）</span>
                    </span>
                </template>
                <template #extra>
                    <a-popover placement="bottom" trigger="hover" >
                        <template #content>
                            <div class="card-edit">
                                <span @click="showDetail(item)" title="查看详情">详情</span>
                            </div>
                            <div class="card-edit">
                                <span v-if="!viewModel" @click="editDetail(item)">修改</span>
                            </div>
                            <div class="card-edit">
                                <a-popconfirm
                                        v-if="!viewModel"
                                        @confirm="deleteData(item)"
                                        cancel-text="否"
                                        class="action-button"
                                        ok-text="是"
                                        title="确定删除吗?">
                                    <span>删除</span>
                                </a-popconfirm>
                            </div>
                        </template>
                        <a-button style="padding: 0 5px" type="link">
                            <UnorderedListOutlined style="font-size: 22px;"/>
                        </a-button>
                    </a-popover>
                </template>
                <VForm :form-columns="cardColumns" :form-data="item" :view-model="true"/>
            </a-card>
        </div>
        <a-result v-if="dataSource.length === 0" status="404" title="还未添加合同"/>
    </a-spin>
    <a-modal
            :footer="null"
            :maskClosable="vModel"
            v-model:open="modalVisible"
            destroyOnClose
            :title="null"
            width="1200px">
        <ContractItem :projectId="projectId" :record="formData" :view-model="vModel" @change="init"/>
    </a-modal>
</template>

<script>
    import {PlusOutlined,UnorderedListOutlined,SyncOutlined} from "@ant-design/icons-vue"
    import item_contract_info from "../../TableStruct/item_contract_info";
    import contractInfo from "../../../assets/api/contractInfo";
    import VForm from "../../../components/form/VForm";
    import Column from "../../../assets/tables/column";
    import ColumnType from "../../../assets/tables/column_type";
    import ContractItem from "./ContractItem";
    import {GetDict} from "../../../assets/utils/general";

    export default {
        name: "ProjectContractInfo",
        inject:["pProjectId"],
        props: ["record","task","viewModel"],
        components: {
            PlusOutlined, VForm, ContractItem,
            UnorderedListOutlined,SyncOutlined
        },
        computed:{
            projectId(){
                if(this.record){
                    return this.record.id;
                }
                if(this.task){
                    return this.task.itemId;
                }
                else{
                    return this.pProjectId
                }
            },
            projectName(){
                if(this.record){
                    return this.record.name;
                }
                if(this.task){
                    return this.task.itemName;
                }
                else{
                    return null
                }
            },
            source1(){//收入合同 type
                let arr = ["1" , "4" , "7"];
                return this.dataSource.filter(item=>arr.indexOf(item.type) !== -1)
            },
            source2(){//支出合同 type 2 , 5 ,6 8
                let arr = ["2","5","6","8"];
                return this.dataSource.filter(item=>arr.indexOf(item.type) !== -1)

            }
        },
        data() {
            return {
                modalVisible: false,
                loading: false,
                formData: null,//提供Form的formData
                formColumns: [],
                contractType:GetDict("contractType"),
                cardColumns: [
                    new Column("名称", "name", ColumnType.String),
                    new Column("合同金额", "amount", ColumnType.Number),
                    new Column("签署方", "associatedName", ColumnType.String),
                    new Column("签订公司", "sigCompany", ColumnType.String)
                        .setDictEnum("companies"),
                    new Column("扫描附件", "fileUrl", ColumnType.PreViewFile)
                ],
                dataSource: [],//提供table的DataSource
                vModel: false,
            }
        },
        methods: {
            init() {
                this.modalVisible = false;
                this.formColumns = new item_contract_info().getFormColumns();
                this.dataSource = [];
                if(this.projectId){
                    this.loading = true;
                    contractInfo.queryAll.post({"itemId": this.projectId}, res => {
                        this.loading = false;
                        if (res.data.flag) {
                            this.dataSource = res.data.data;
                        } else {
                            this.dataSource = []
                        }
                    })
                }
                else{
                    this.$message.error("未知查询参数/条件")
                }
            },
            showDetail(record) {
                this.formData = record;
                this.modalVisible = true;
                this.vModel = true;
            },
            editDetail(record) {
                this.formData = record;
                this.modalVisible = true;
                this.vModel = false;
            },
            deleteData(record) {
                let data = {id: record.id};
                contractInfo.delete.urlPost(data, res => {
                    if (res.data.flag) {
                        this.$message.success(res.data.msg);
                        this.dataSource = this.dataSource.filter(item=>item.id !== record.id)
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            // saveData(record) {
            //     if(record.type === "7"){//无需合同情况说明
            //         record.name = "无需合同情况说明";
            //         record.number = this.projectName;
            //         record.signState = "1";
            //     }
            //     record.itemId = this.projectId;
            //     contractInfo.saveOrUpd.post(record, res => {
            //         if (res.data.flag) {
            //             this.$message.success(res.data.msg);
            //             this.modalVisible = false;
            //             this.init()
            //         } else {
            //             this.$message.error(res.data.msg);
            //         }
            //     });
            // },
            addData() {
                this.formData = new item_contract_info().getFormData();
                this.formData.itemId = this.projectId;
                this.modalVisible = true;
                this.vModel = false;
            },
            getFileType(type){
                let file = this.contractType.find(item =>item.value === type);
                return file.label
            }
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>