<!--
* @program: TYHOA2 
* @author: ly
* @component:CardManager 
* @description: 
* @create: 2024-10-10 11:25
-->
<template>
    <a-tabs style="margin:10px" v-model:activeKey="activeKey">
        <a-tab-pane key="7" tab="流水汇总">
            <div class="column-flex" >
                <div class="row-flex">
                    <div style="height: 100%;overflow: auto;width: 200px;background: #fafafa;">
                        <MenuCardItem :menu="menu1" :enable="currentComp === 'ProjectPay'" @click="change('ProjectPay')"/>
                        <MenuCardItem :menu="menu4" :enable="currentComp === 'ProjectFlows'" @click="change('ProjectFlows')"/>
                        <MenuCardItem :menu="menu2" :enable="currentComp === 'OtherPay'" @click="change('OtherPay')"/>
                        <MenuCardItem :menu="menu3" :enable="currentComp === 'OtherIncome'" @click="change('OtherIncome')"/>
                        <MenuCardItem :menu="menu5" :enable="currentComp === 'InvoiceIncome'" @click="change('InvoiceIncome')"/>
                        <MenuCardItem :menu="menu6" :enable="currentComp === 'InvoiceOutcome'" @click="change('InvoiceOutcome')"/>
                    </div>
                    <div style="height: 100%;overflow: auto;min-width: 600px;width: calc(100% - 200px);background: #fafafa;box-shadow: 0px 8px 16px 1px rgba(37,84,165,0.15);">
                        <component :is="currentComp" :bankCards="bankCards"/>
                    </div>
                </div>
            </div>
        </a-tab-pane>
        <a-tab-pane key="6" tab="账单一览" v-if="showBill">
            <ShowBills :record="currentRecord"/>
        </a-tab-pane>
        <a-tab-pane key="1" tab="项目收入流水">
            <ProjectInflow :record="currentRecord"/>
        </a-tab-pane>
        <a-tab-pane key="2" tab="项目支出流水">
            <ProjectOutflow :record="currentRecord"/>
        </a-tab-pane>
        <a-tab-pane key="3" tab="其他收入">
            <OtherInflow :record="currentRecord"/>
        </a-tab-pane>
        <a-tab-pane key="4" tab="其他支出">
            <OtherOutflow :record="currentRecord"/>
        </a-tab-pane>
        <a-tab-pane key="5" tab="其他账户转入">
            <FromOtherBank :record="currentRecord"/>
        </a-tab-pane>
    </a-tabs>

</template>

<script>
    export default {
        name: "CardManager",
        data() {
            return {}
        },
        methods: {
            init() {
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>