<!--
* @program: TYHOA2 
* @author: ly
* @component:ProjectUseUp 
* @description: 项目消耗一览
* @create: 2024-07-24 17:57
-->
<template>
    <div style="height:calc( 100% - 48px)">
        <LTable :title="'消耗合计：'+sum.applyAmount.toFixed(2)+'元'" :columns="tableColumns" :data-source="dataSource" :view-model="true">
            <template #action="{record}">
                <div class="action-column">
                    <a-tooltip  title="查看所属账单">
                        <a-button class="action-button" type="link" @click="showBelong(record)">
                            <LinkOutlined class="pointer"/>
                        </a-button>
                    </a-tooltip>
                </div>
            </template>
        </LTable>
    </div>
</template>

<script>
    import {
        ExclamationCircleOutlined,LinkOutlined
    } from "@ant-design/icons-vue"
    import LTable from "../../../components/table/LTable";
    import Column from "../../../assets/tables/column";
    import ColumnType from "../../../assets/tables/column_type";
    import recordFlow from "../../../assets/api/recordFlow";
    import {h} from "vue";
    import { notification } from 'ant-design-vue';
    import api_record from "../../../assets/api/record";
    export default {
        name: "ProjectUseUp",
        props:["record"],
        components:{
            ExclamationCircleOutlined,LinkOutlined,LTable
        },
        data(){
            return{
                dataSource:null,//提供table的DataSource
                tableColumns:[
                    new Column("支出时间","flowTime",ColumnType.Date,false).setTableView(80),
                    new Column("费用类别","categoryId",ColumnType.Category,true).setTableView(150),
                    new Column("有发票金额(元)","haveInvoiceAmount",ColumnType.Number,true).setTableView(80),
                    new Column("无发票金额(元)","noInvoiceAmount",ColumnType.Number,true).setTableView(80),
                    new Column("报销金额(元)","applyAmount",ColumnType.Number,true).setTableView(80),
                    new Column("备注","remarks",ColumnType.String,true).setTableView(250),
                    new Column("报销凭证","invoiceUrl",ColumnType.PreViewFile,true).setTableView(80),
                    new Column("操作","actions","actions",false).setTableView(60)
                ], //提供table的字-段集 this.table.getTableColumns()

                loading:false,//table的loading状态
                queryOptions:{
                    itemId:null,
                    entryState:"1"
                },
                visible:false,
                sum:{
                    applyAmount:0,
                    noInvoiceAmount:0,
                    haveInvoiceAmount:0
                }
            }
        },
        methods:{
            init(){
                this.sum.applyAmount = 0;
                this.queryOptions.itemId = this.record.id;
                recordFlow.queryAll.urlPost(this.queryOptions,res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                        for(let i in this.dataSource){
                            this.sum.applyAmount += this.dataSource[i].applyAmount;
                        }
                    }
                })
            },
            showBelong(record){
                let data={id:record.recordId};
                api_record.queryOne.urlPost(data,res=>{
                    if(res.data.flag){
                        let {title,userName,recordYearMonth}=res.data.data;
                        notification.open(
                            {
                                message: "账单标题："+title,
                                description: h('div',[
                                    h("div",{style:{"color":'red'}},"报账人："+userName),
                                    h("div","账单日期："+recordYearMonth),
                                ])
                            })
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>