<!--
* @description：年初余额管理
* @author:rzl
* @date:2022/7/27 15:43
-->
<template>
    <div class="column-flex">
          <div class="search-bar">
            <a-tooltip  title="添加角色">
              <a-button  type="dashed"  @click="addData">
                <template #icon><Add /></template>
              </a-button>
            </a-tooltip>
            <a-tooltip  title="刷新">
              <a-button  type="dashed"  @click="refresh">
                <template #icon><Reset /></template>
              </a-button>
            </a-tooltip>
          </div>

          <!-- table start -->
          <LTable ref="ltable"
                  :page-size="pageParam.limit"
                  :columns="tableColumns"
                  :data-source="dataSource"
                  @pageChange="pageChange">
            <template #action="{index,record}">
              <div class = "action-column">
                <a-button type="link" @click="editData(index,record)">
                  <template #icon><Edit title="编辑"/></template>
                </a-button>
                <a-popconfirm
                    class="action-button"
                    title="确定删除吗?"
                    okText="确定"
                    cancelText="取消"
                    @confirm="deleteData(index,record)"
                >
                  <a-button type="link">
                    <template #icon><delete title="删除" style="color: red"/></template>
                  </a-button>
                </a-popconfirm>
              </div>
            </template>
          </LTable>
          <!-- table end -->
    </div>
    <a-modal v-model:open="visible"
             :maskClosable="false"
             :title="title"  width="30%"
             :destroyOnClose="true"
    >

    <l-form  ref="bill_form"
             :row-size="1" :labelSpan="3"
             v-model:form-data="formData" :form-columns="formColumns"  :viewModel="false"  />

    <template #footer>
      <a-button type="primary" @click="submit">提交</a-button>
      <a-button key="back" @click="visible =false">取消</a-button>
    </template>
  </a-modal>
</template>

<script>
import Icons from "@/page/common/Icons";
import LTable from "@/components/table/LTable";
import LForm from "@/components/form/LForm";

import yearBalance_item from "@/page/Finance/FinancialManager/yearBalanceMange/configs/yearBalance_item";
import CashBalance from "@/assets/api/CashBalance";
export default {
  name: "yearBalance",
  mixins:[Icons],
  components:{
    LForm,LTable
  },
  data(){
    return{
      pageParam:{
        page: 1,
        limit: 15,
      },
      tableColumns:new yearBalance_item().getTableColumns(),
      dataSource:[],
      loading:false,
      formColumns:new yearBalance_item().getFormColumns(),
      formData:{},


      title:null,
      visible:false,//角色弹出框
    }
  },
  created() {
    this.init();
  },
  methods:{
    init(){
      this.loading = true;
      CashBalance.queryPage.post(this.pageParam,res=>{
        this.loading = false;
        if(res.data.flag){
          this.dataSource = res.data.data;
          this.$refs.ltable.setPage(res.data.count);
        }
      });
    },
      pageChange(arg){
          this.pageParam.page = arg.pageNum;
          this.pageParam.limit = arg.pageSize;
          this.loading = true;
          CashBalance.queryPage.post(this.pageParam,res=>{
              this.loading = false;
              if(res.data.flag){
                  this.dataSource = res.data.data;
                  this.$refs.ltable.setTotalSize(res.data.count);
              }
              else{
                  this.$message.error(res.data.msg);
              }
          })
      },
    refresh(){
        this.pageParam.page = 1;
        this.pageParam.limit = 1;
        this.init()
    },
    addData(){
      this.formData = {};
      this.visible = true;
      this.title = "新增信息";
    },
    deleteData(index,record){
      let data ={id:record.id};
      CashBalance.delete.urlPost(data,res=>{
        if(res.data.flag){
          this.init();
          this.$message.success(res.data.msg);
        }
        else{
          this.$message.error(res.data.msg);
        }
      })
    },
    editData(index,record){
      this.formData = record;
      this.visible = true;
      this.title = "编辑信息";
    },
    submit(){
      CashBalance.saveOrUpd.post(this.formData,res=>{
        if(res.data.flag){
          this.$message.success(res.data.msg);
          this.init();
          this.visible = false;
        }
        else{
          this.$message.error(res.data.msg);
        }
      })
    },


  },
}
</script>

<style scoped>

</style>
