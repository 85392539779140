/** 
* 自动生成文件，请勿修改 
* @name: 任务:任务-月度-员工完成占比（月度绩效）
* @description: 任务:任务-月度-员工完成占比（月度绩效）
* @create: 2024/9/29 15:41:49
* @swagger: 2.0
* 合计:14条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const taskMonthUser = { 
	/** 1 summary:查询-项目绩效分配情况表
	description:参数：year:项目年度;name:项目名称;auditState:审核状态：0：未审核；1：已通过；2：未通过;haveTask:有无分配任务，true:有分配任务；false：未分配任务;startMonth:绩效分配开始月份：格式：yyyy-MM;endMonth:绩效分配结束月份：格式：yyyy-MM
	method:post
	params:需要配合Column函数使用
	*/ 
	itemPersonMoney : new APIObject.postObject("/api/taskMonthUser/itemPersonMoney.do"), 
	/** 2 summary:查询-单条
	description:参数：单条数据的主键id,
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/taskMonthUser/queryOne.do"), 
	/** 3 summary:查询-分页
	description:参数：pageBean(分页对象，limit，page即可)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/taskMonthUser/queryPage.do"), 
	/** 4 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/taskMonthUser/delete.do"), 
	/** 5 summary:导出-年度绩效汇总（个人）
	description:参数：startMonth:开始月度（包含本月）;endMonth:结束月度（包含本月）;userId:员工id;depId:部门id(得到部门所有人员)；state：在职情况：0：离职；1：在职
	method:post
	params:需要配合Column函数使用
	*/ 
	personExport : new APIObject.postObject("/api/taskMonthUser/personExport.do"), 
	/** 6 summary:新增-单条
	description:参数：对象TaskMonthUserVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/taskMonthUser/saveOrUpd.do"), 
	/** 7 summary:查询-分页(任务负责人查询时不显示钱，只显示比例)
	description:参数：pageBean(分页对象，limit，page即可)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPageOnlyRatio : new APIObject.postObject("/api/taskMonthUser/queryPageOnlyRatio.do"), 
	/** 8 summary:查询-不分页(任务负责人查询时不显示钱，只显示比例)
	description:undefined
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAllOnlyRatio : new APIObject.postObject("/api/taskMonthUser/queryAllOnlyRatio.do"), 
	/** 9 summary:查询-部门目标产值，实际产值，用于更新部门负责人绩效得分
	description:得到部门目标产值（年度责任书合同额*0.15/12），实际产值，当月下发任务金额总计,用于更新部门负责人绩效得分
	method:post
	params:需要配合Column函数使用
	*/ 
	queryDep : new APIObject.postObject("/api/taskMonthUser/queryDep.do"), 
	/** 10 summary:查询-年度个人绩效汇总表
	description:参数：startMonth:开始月度（包含本月）;endMonth:结束月度（包含本月）;userId:员工id;depId:部门id(得到部门所有人员)；state：在职情况：0：离职；1：在职
	method:post
	params:需要配合Column函数使用
	*/ 
	personReport : new APIObject.postObject("/api/taskMonthUser/personReport.do"), 
	/** 11 summary:导出-项目-个人分配绩效报表导出
	description:参数：year:项目年度;name:项目名称;auditState:审核状态：0：未审核；1：已通过；2：未通过;haveTask:有无分配任务，true:有分配任务；false：未分配任务;startMonth:绩效分配开始月份：格式：yyyy-MM;endMonth:绩效分配结束月份：格式：yyyy-MM
	method:post
	params:需要配合Column函数使用
	*/ 
	itemPersonExport : new APIObject.postObject("/api/taskMonthUser/itemPersonExport.do"), 
	/** 12 summary:删除-批量
	description:参数：数据的主键id,逗号分隔多条：1,2,3,4.。
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/taskMonthUser/batchDelete.do"), 
	/** 13 summary:保存/修改-批量(一次新增/修改一个任务的就行)
	description:参数：List<TaskMonthUserVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSaveOrUpd : new APIObject.postObject("/api/taskMonthUser/batchSaveOrUpd.do"), 
	/** 14 summary:查询-不分页
	description:undefined
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/taskMonthUser/queryAll.do"), 
};
export default taskMonthUser;