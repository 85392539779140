<!--
* @program: office_automation
* @author: ly
* @component:CardFlows
* @description: 银行卡收入支出一览
* @create: 2022-06-24 17:08
-->
<template>
    <MonthPicker style="width: 120px" v-model:value="filterMonth" />
    <span>  支出合计：{{sum[0].toFixed(2)}}</span>
    <span>  收入合计：{{sum[1].toFixed(2)}}</span>
    <div style="height:600px">
        <LTable :loading="loading" :data-source="dataSource" :table-columns="tableColumns"/>
    </div>
</template>

<script>
import dayjs from "dayjs";
import api_record from "@/assets/api/record";
import api_item_collection_info from "@/assets/api/collectionInfo";
import payApply from "@/assets/api/payApply";
import income from "@/assets/api/income";
import otherPay from "@/assets/api/otherPay";
import LTable from "@/components/table/LTable";

import Column from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type";
import {uuid} from "@/assets/utils/general";
export default {
    name: "CardFlows",
    props:["record","showBill"],
    components:{
      LTable
    },
    computed:{
        dataSource(){
            return this.flows.filter(item=>{
                if(this.filterMonth){
                    return item.yearMonth === this.filterMonth
                }
                else{
                    return true
                }
            })
        },
        sum(){////"1" "2" "3" "4"  "5" 银行直付  收入  转出  转入  报账单
            let sumIn = 0;
            let sumOut = 0;
            for(let i in this.dataSource){
                if(this.dataSource[i].type === "1" || this.dataSource[i].type === "3" || this.dataSource[i].type === "5"){
                    sumIn += this.dataSource[i].value
                }
                else{
                    sumOut += this.dataSource[i].value
                }
            }
            return [sumIn,sumOut];
        }
    },
    data() {
        return {
            tableColumns:[
                new Column("序号","_index",ColumnType.Index).setTableView(60),
                new Column("类型","type",ColumnType.Enum).setTableView(100)
                .setEnum(["1","2","3","4","5"],["银行直付" , "收入",  "转出" , "转入","个人报账"],
                    ["red","blue","orange","green","pink"]),
                new Column("时间","date",ColumnType.String).setTableView(200),
                new Column("金额","value",ColumnType.Number).setTableView(200),
                new Column("来源","from",ColumnType.String).setTableView(200),
            ],
            startMoment:null,
            flows:[],
            loading:false,
            filterMonth:null

        }
    },
    methods: {
        async init() {
            this.loading = true;
            this.flows = [];
            /** bill */
            if(this.showBill){
                let data1 = {
                    entryState:"4", //入账状态（0：未入账；1：对公入账；2：拒绝入账；3：撤销入账 ;4：私人入账）
                    approvalState:"1", //审核状态（0：未完成；1：已完成）
                    submitState:"1" //提交状态（0：未提交；1：已提交）
                };
                let data2 = {
                    entryState:"1", //入账状态（0：未入账；1：对公入账；2：拒绝入账；3：撤销入账 ;4：私人入账）
                    approvalState:"1", //审核状态（0：未完成；1：已完成）
                    submitState:"1" //提交状态（0：未提交；1：已提交）
                };
                let res1 = await api_record.queryAll.urlAsyncPost(data1);
                if(res1.flag){
                    let r1 = res1.data.filter(item=>{
                        return  dayjs(item.recordYearMonth).isSame(this.startMoment,'month') || dayjs(item.recordYearMonth).isAfter(this.startMoment,'month')
                    });
                    for(let i in r1){
                        /*let {recordYearMonth,amount,updateTime} = r1[i];*/
                      let {recordYearMonth,amount} = r1[i];
                        this.flows.push(new Flows(recordYearMonth,"5",amount,r1[i],"个人报账","YYYY-MM"))
                    }
                }

                let res2 = await api_record.queryAll.urlAsyncPost(data2);
                if(res2.flag){
                    let r2 = res2.data.filter(item=>{
                        return  dayjs(item.recordYearMonth).isSame(this.startMoment,'month') || dayjs(item.recordYearMonth).isAfter(this.startMoment,'month')
                    });
                    for(let i in r2){
                        let {recordYearMonth,amount} = r2[i];
                        this.flows.push(new Flows(recordYearMonth,"5",amount,r2[i],"个人报账","YYYY-MM"))
                    }
                }
            }

            /** ProjectInflow */
            let data3 = {
                bankId:this.record.id
            };
            let res3 = await api_item_collection_info.queryAllByObj.asyncPost(data3);
            if(res3.flag){
                let r3 = res3.data;
                for(let i in r3){
                    let {collectionTime,collectionAmount} = r3[i];
                    this.flows.push(new Flows(collectionTime,"2",collectionAmount,r3[i],"项目收入"))
                }
            }


            /** projectOutflow */
            let data4 = {
                bankId: this.record.id,
                payState:"1"
            };
            let res4 = await payApply.queryAll.asyncPost(data4);
            if(res4.flag){
                let r4 = res4.data;
                for(let i in r4){
                    let {payTime,payAmount} = r4[i];
                    this.flows.push(new Flows(payTime,"1",payAmount,r4[i],"项目支出"))
                }
            }


            /**OtherInflow */

            let data5 = {
                bankId:this.record.id
            };
            let res5 = await income.queryAll.asyncPost(data5);
            if(res5.flag){
                let r5 = res5.data;
                for(let i in r5){
                    let {collectDate,collectAmount} = r5[i];
                    this.flows.push(new Flows(collectDate,"2",collectAmount,r5[i],"其他收入"))
                }
            }


            /**OtherOutflow */
            let data6 = {
                "approvalState": "1",
                "payBankId": this.record.id,
            };
            let res6 = await otherPay.queryAll.asyncPost(data6);
            if(res6.flag){
                let r6 = res6.data.filter(item=>{
                    let time = dayjs(item.payDate,"YYYY-MM");
                    return  time.isSame(this.startMoment,'month') || time.isAfter(this.startMoment,'month')
                });
                for(let i in r6){
                    let {payDate,payAmount,type} = r6[i];
                    if(type === "4"){ // ["银行账户间转账","由银行卡取出到现金账户","由现金账户存入银行卡","支出"]
                        this.flows.push(new Flows(payDate,"1",payAmount,r6[i],"其他支出"))
                    }
                    else{
                        this.flows.push(new Flows(payDate,"3",payAmount,r6[i],"其他支出"))
                    }

                }
            }

            /**FromOtherBank */
            let data7 = {
                "approvalState": "1",
                "receiveBankId": this.record.id,
            };
            let res7 = await otherPay.queryAll.asyncPost(data7);
            if(res7.flag){
                let r7 = res7.data.filter(item=>{
                    let time = dayjs(item.payDate,"YYYY-MM");
                    return  time.isSame(this.startMoment,'month') || time.isAfter(this.startMoment,'month')
                });
                for(let i in r7){
                    let {payDate,payAmount,type} = r7[i];
                    if(type === "4"){ // ["银行账户间转账","由银行卡取出到现金账户","由现金账户存入银行卡","支出"]
                        this.flows.push(new Flows(payDate,"1",payAmount,r7[i],"其他账户转入"))
                    }
                    else{
                        this.flows.push(new Flows(payDate,"2",payAmount,r7[i],"其他账户转入"))
                    }

                }
            }
            this.flows.sort((a, b) => {
                return b.time.valueOf() - a.time.valueOf();
            });
            this.loading = false
        }
    },
    created() {
        if(this.record){
            this.startMoment = dayjs(this.record.balanceDate).format("YYYY-MM");
        }
        this.filterMonth = dayjs(new Date().valueOf()).format("YYYY-MM");
        this.init()
    }
}

class Flows{
    constructor(time,type,value,data,from,format) {
        this.id = uuid(8,16);
        this.time = dayjs(time);
        this.type = type; //"1" "2" "3" "4"  "5" 银行直付  收入  转出  转入  报账单
        this.value = value;
        this.data = data;
        this.from = from;
        this.format = format?format:"YYYY-MM-DD";
        this.date = this.time.format(this.format);
        this.yearMonth = this.time.format("YYYY-MM");
    }
}
</script>

<style scoped>

</style>
