/** 
* 自动生成文件，请勿修改 
* @name: 系统:用户-角色关系管理模块
* @description: 用户-角色关系管理-增删改查
* @create: 2024/9/29 15:41:49
* @swagger: 2.0
* 合计:8条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const userRoleRelation = { 
	/** 1 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/userRoleRelation/delete.do"), 
	/** 2 summary:保存/修改-批量
	description:参数：List<UserRoleRelationVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSaveOrUpd : new APIObject.postObject("/api/userRoleRelation/batchSaveOrUpd.do"), 
	/** 3 summary:查询-全部不分页(根据userId,roleId)
	description:参数：userId(用户id)、roleId(角色id)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/userRoleRelation/queryAll.do"), 
	/** 4 summary:删除-批量
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/userRoleRelation/batchDelete.do"), 
	/** 5 summary:用户绑定角色-批量
	description:参数：List<UserRoleRelationVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchBinding : new APIObject.postObject("/api/userRoleRelation/batchBinding.do"), 
	/** 6 summary:保存/修改-单条
	description:参数：单条数据对象UserRoleRelationVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/userRoleRelation/saveOrUpd.do"), 
	/** 7 summary:查询-分页(根据userId,roleId)
	description:参数：pageBean分页对象(传page、limit即可)、userId(用户id)、roleId(角色id)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/userRoleRelation/queryPage.do"), 
	/** 8 summary:查询-单条(根据主键)
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/userRoleRelation/queryOne.do"), 
};
export default userRoleRelation;