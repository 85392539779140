/** 
* 自动生成文件，请勿修改 
* @name: 系统:人员管理-系统用户管理模块
* @description: 系统用户管理模块-增删改查(修改过)
* @create: 2024/9/29 15:41:49
* @swagger: 2.0
* 合计:16条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const userInfo = { 
	/** 1 summary:系统用户管理-所有数据查询
	description:参数：queryText(姓名或手机号等模糊查询)、disableState禁用状态：0：禁用；1：启用
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/userInfo/queryAll.do"), 
	/** 2 summary:更改用户离职状态(管理员操作)
	description:参数：id用户数据主键
	method:post
	params:需要配合Column函数使用
	*/ 
	leaveOffice : new APIObject.postObject("/api/userInfo/leaveOffice.do"), 
	/** 3 summary:用户密码修改（个人中心）
	description:参数：id数据主键；oldPassWord老密码；newPassWord新密码
	method:post
	params:需要配合Column函数使用
	*/ 
	passWordModify : new APIObject.postObject("/api/userInfo/passWordModify.do"), 
	/** 4 summary:查询-根据角色id查询该角色下的所有用户
	description:参数：roleId(角色id)
	method:post
	params:需要配合Column函数使用
	*/ 
	findUserByRole : new APIObject.postObject("/api/userInfo/findUserByRole.do"), 
	/** 5 summary:系统用户管理-单条数据查询
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/userInfo/queryOne.do"), 
	/** 6 summary:系统用户管理-批量保存/修改
	description:参数：List<UserInfoVO>
	method:post
	params:需要配合Column函数使用
	*/ 
	batchSaveOrUpd : new APIObject.postObject("/api/userInfo/batchSaveOrUpd.do"), 
	/** 7 summary:系统用户管理-保存/修改
	description:参数：单条数据对象UserInfoVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/userInfo/saveOrUpd.do"), 
	/** 8 summary:系统用户管理-批量删除
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/userInfo/batchDelete.do"), 
	/** 9 summary:批量用户启用/禁用
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchstateModify : new APIObject.postObject("/api/userInfo/batchstateModify.do"), 
	/** 10 summary:初始化某个用户的密码(管理员操作)
	description:参数：id用户数据主键
	method:post
	params:需要配合Column函数使用
	*/ 
	passWordInitial : new APIObject.postObject("/api/userInfo/passWordInitial.do"), 
	/** 11 summary:系统用户管理-单条删除
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/userInfo/delete.do"), 
	/** 12 summary:单个用户启用/禁用
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	stateModify : new APIObject.postObject("/api/userInfo/stateModify.do"), 
	/** 13 summary:更新头像文件路径
	description:参数：用户主键id，头像文件路径：先调用upload接口得到
	method:post
	params:需要配合Column函数使用
	*/ 
	updHeadImg : new APIObject.postObject("/api/userInfo/updHeadImg.do"), 
	/** 14 summary:修改用户是否可以报账所有项目
	description:参数：单条数据的主键id，allItemFlag：true/false
	method:post
	params:需要配合Column函数使用
	*/ 
	allItemFlag : new APIObject.postObject("/api/userInfo/allItemFlag.do"), 
	/** 15 summary:系统用户管理-分页查询
	description:参数：pageBean分页对象(传page、limit即可)、queryText(姓名或手机号等模糊查询)、disableState禁用状态：0：禁用；1：启用
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/userInfo/queryPage.do"), 
	/** 16 summary:查询-根据部门id查询该部门下的所有用户
	description:参数：departmentId(部门id)
	method:post
	params:需要配合Column函数使用
	*/ 
	findUserByDepart : new APIObject.postObject("/api/userInfo/findUserByDepart.do"), 
};
export default userInfo;