/** 
* 自动生成文件，请勿修改 
* @name: 项目：任务-周报管理模块
* @description: 项目：任务-周报管理模块-------2024-01-09日新增
* @create: 2024/9/29 15:41:49
* @swagger: 2.0
* 合计:12条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const weekWork = { 
	/** 1 summary:提交-单条（部门领导的操作）
	description:参数：单条数据的主键id，提交-单条（部门领导的操作）
	method:post
	params:需要配合Column函数使用
	*/ 
	leaderSubmit : new APIObject.postObject("/api/weekWork/leaderSubmit.do"), 
	/** 2 summary:查询-不分页
	description:参数：WeekWorkQueryVO对象，查询条件太多而单独封装的对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/weekWork/queryAll.do"), 
	/** 3 summary:删除-批量
	description:参数：半角逗号分隔的多个id主键,格式：id1,id2,id3......
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/weekWork/batchDelete.do"), 
	/** 4 summary:查询-单条数据
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/weekWork/queryOne.do"), 
	/** 5 summary:审核-单条（部门领导的操作）
	description:参数：单条数据的主键id，审核-单条（部门领导的操作）,若是未通过，填写人提交状态会变成未提交状态，填写人可编辑重新提交
	method:post
	params:需要配合Column函数使用
	*/ 
	approve : new APIObject.postObject("/api/weekWork/approve.do"), 
	/** 6 summary:取消提交-单条（部门领导的操作）
	description:参数：单条数据的主键id，取消提交-单条（部门领导的操作）
	method:post
	params:需要配合Column函数使用
	*/ 
	cancelLeaderSubmit : new APIObject.postObject("/api/weekWork/cancelLeaderSubmit.do"), 
	/** 7 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/weekWork/delete.do"), 
	/** 8 summary:提交-单条（填写人进行提交）
	description:参数：单条数据的主键id，提交-单条（填写人进行提交自己的周报）
	method:post
	params:需要配合Column函数使用
	*/ 
	submit : new APIObject.postObject("/api/weekWork/submit.do"), 
	/** 9 summary:审阅-单条（总经办的操作）
	description:参数：单条数据的主键id，审阅-单条（总经办的操作）
	method:post
	params:需要配合Column函数使用
	*/ 
	review : new APIObject.postObject("/api/weekWork/review.do"), 
	/** 10 summary:保存/修改-单个
	description:参数：单条数据对象WeekWorkVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/weekWork/saveOrUpd.do"), 
	/** 11 summary:查询-分页
	description:参数：(page、limit,分页参数，)、WeekWorkQueryVO对象，查询条件太多而单独封装的对象
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/weekWork/queryPage.do"), 
	/** 12 summary:取消提交-单条（填写人的操作）
	description:参数：单条数据的主键id，取消提交-单条（填写人的操作）
	method:post
	params:需要配合Column函数使用
	*/ 
	cancelSubmit : new APIObject.postObject("/api/weekWork/cancelSubmit.do"), 
};
export default weekWork;