<!--
* @program: TYHOA2 
* @author: ly
* @component:BankFlowRecords
* @description: 项目支付付款/其他支付付款
* @create: 2024-09-18 14:27
-->
<template>
    <div class="column-flex" >
        <div class="row-flex">
            <div style="height: 100%;overflow: auto;width: 200px;background: #fafafa;">
                <MenuCardItem :menu="menu1" :enable="currentComp === 'ProjectPay'" @click="change('ProjectPay')"/>
                <MenuCardItem :menu="menu4" :enable="currentComp === 'ProjectFlows'" @click="change('ProjectFlows')"/>
                <MenuCardItem :menu="menu2" :enable="currentComp === 'OtherPay'" @click="change('OtherPay')"/>
                <MenuCardItem :menu="menu3" :enable="currentComp === 'OtherIncome'" @click="change('OtherIncome')"/>
                <MenuCardItem :menu="menu5" :enable="currentComp === 'InvoiceIncome'" @click="change('InvoiceIncome')"/>
                <MenuCardItem :menu="menu6" :enable="currentComp === 'InvoiceOutcome'" @click="change('InvoiceOutcome')"/>
            </div>
            <div style="height: 100%;overflow: auto;min-width: 600px;width: calc(100% - 200px);background: #fafafa;box-shadow: 0px 8px 16px 1px rgba(37,84,165,0.15);">
                <component :is="currentComp" :bankCards="bankCards"/>
            </div>
        </div>
    </div>
</template>

<script>
    import MenuCardItem from "../../../Other/OAMenuDefaultPage/MenuCardItem";
    import ProjectPay from "./ProjectPay";
    import OtherPay from "./OtherPay";
    import bankBalance from "../../../../assets/api/bankBalance";
    import OtherIncome from "./OtherIncome";
    import ProjectFlows from "./ProjectFlows";
    import InvoiceIncome from "./InvoiceIncome";
    import InvoiceOutcome from "./InvoiceOutcome";

    export default {
        name: "BankFlowRecords",
        components:{
            MenuCardItem,ProjectPay,OtherPay,OtherIncome,
            ProjectFlows,InvoiceIncome,InvoiceOutcome
        },
        data() {
            return {
                currentComp:"ProjectPay",
                menu1:{
                    cnName:'项目外协支付',
                    meta:{
                        content:"从账户支付已完成审核的申请"
                    }
                },
                menu2:{
                    cnName:'资金账户支出',
                    meta:{
                        content:"银行间转账，支付"
                    }
                },
                menu3:{
                    cnName:'其他收入',
                    meta:{
                        content:"项目收款之外的收入"
                    }
                },
                menu4:{
                    cnName:'项目收款一览',
                    meta:{
                        content:"项目收款之外的收入"
                    }
                },
                menu5:{
                    cnName:'收款发票一览',
                    meta:{
                        content:"项目收款发票"
                    }
                },
                menu6:{
                    cnName:'付款发票一览',
                    meta:{
                        content:"外协支付发票"
                    }
                },
                bankCards:[]
            }
        },
        methods: {
            change(name){
                this.currentComp = name;
            }
        },
        created() {
            bankBalance.queryAll.post({},res=>{
                if(res.data.flag){
                    this.bankCards = res.data.data;
                }
                else{
                    this.bankCards = [];
                }
            });
        }
    }
</script>

<style scoped>

</style>