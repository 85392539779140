/** 
* 自动生成文件，请勿修改 
* @name: 支付:外协支付申请
* @description: 外协支付申请(三期新增)
* @create: 2024/9/29 15:41:49
* @swagger: 2.0
* 合计:13条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const payApply = { 
	/** 1 summary:单条删除
	description:参数：单条数据的主键id(支付申请删除时，审核流水将删除)
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/payApply/delete.do"), 
	/** 2 summary:外协支出-统计每月度外协支出总计（各月度下总计）
	description:参数：OtherPayStatVO中yearMonth可以是年/年月
	method:post
	params:需要配合Column函数使用
	*/ 
	outPayStat : new APIObject.postObject("/api/payApply/outPayStat.do"), 
	/** 3 summary:分页查询
	description:参数：分页对象(传page、limit即可)
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/payApply/queryPage.do"), 
	/** 4 summary:撤销提交（在没有任何人审核情况下，可以撤销）
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	cancelSubmit : new APIObject.postObject("/api/payApply/cancelSubmit.do"), 
	/** 5 summary:所有数据查询
	description:参数：所有参数可有可无
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/payApply/queryAll.do"), 
	/** 6 summary:保存-（新增以往历史外协支付记录，无需银行卡，无需审核）
	description:参数：PayApplyVO(支付申请对象)
	method:post
	params:需要配合Column函数使用
	*/ 
	save : new APIObject.postObject("/api/payApply/save.do"), 
	/** 7 summary:查询需要当前登录用户审批的申请-分页
	description:参数：无
	method:post
	params:需要配合Column函数使用
	*/ 
	pageMyApproval : new APIObject.postObject("/api/payApply/pageMyApproval.do"), 
	/** 8 summary:单条数据查询
	description:参数：数据主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/payApply/queryOne.do"), 
	/** 9 summary:支付-点击这里时会将改笔申请的付款金额同步到付款记录中
	description:参数：支付申请主键id；支付状态：0：未支付；1：已支付;点击这里时会将改笔申请的付款金额同步到付款记录中(页面进行相关提示，提醒操作员不要再到外协支付处重复录入)
	method:post
	params:需要配合Column函数使用
	*/ 
	payConfirm : new APIObject.postObject("/api/payApply/payConfirm.do"), 
	/** 10 summary:提交
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	submit : new APIObject.postObject("/api/payApply/submit.do"), 
	/** 11 summary:查询需要当前登录用户审批的申请-不分页
	description:参数：state,
	method:post
	params:需要配合Column函数使用
	*/ 
	queryMyApproval : new APIObject.postObject("/api/payApply/queryMyApproval.do"), 
	/** 12 summary:保存/修改
	description:参数：PayApplyVO(支付申请对象)
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/payApply/saveOrUpd.do"), 
	/** 13 summary:根据申请id导出对应支付审批表
	description:参数：id
	method:post
	params:需要配合Column函数使用
	*/ 
	exportById : new APIObject.postObject("/api/payApply/exportById.do"), 
};
export default payApply;