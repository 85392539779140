<!--
* @program: TYHOA2 
* @author: ly
* @component:QCTaskProcess 
* @description: 
* @create: 2024-09-27 14:19
-->
<template>
    <div class="column-flex" style="position: relative;overflow: hidden">
        <div class="search-bar">
            <a-tooltip title="刷新">
                <a-button  type="dashed"  @click="init">
                    <template #icon><SyncOutlined /></template>
                </a-button>
            </a-tooltip>
            <a-tooltip v-if="!viewModel"  title="添加任务工序">
                <a-button  type="dashed"  @click="modalAdd">
                    <template #icon><PlusOutlined /></template>
                </a-button>
            </a-tooltip>
            <div style="margin:0 5px">名称：</div>
            <a-input  placeholder="工序名称" style="width: 150px" v-model:value="filter.title" />
        </div>
        <div class="form-title">任务完成期限为<span style="color: red">{{new Date(apply.planCommitDate).toLocaleDateString()}}</span></div>
        <div style="height: 100%;display:flex">
            <div style="width:50%;border: #c8c8c8 dashed 1px">
                <LTable @click="rowClick" :data-source="source" :columns="tableColumns" >
                    <template #action="{record}">
                        <div class="action-column">
                            <a-button class="action-button" type="link" @click="showDetail(record)">
                                <SearchOutlined class="pointer"/>
                            </a-button>
                            <a-tooltip title="添加成员">
                                <a-button class="action-button" v-if="!viewModel && !record.state" @click="addUser(record)" type="link">
                                    <TeamOutlined class="pointer"/>
                                </a-button>
                            </a-tooltip>
                            <a-tooltip v-if="!viewModel && !record.state" title="工序编辑">
                                <a-button  class="action-button" type="link" @click="editDetail(record)">
                                    <EditFilled class="pointer"/>
                                </a-button>
                            </a-tooltip>
                            <a-tooltip v-if="!viewModel && !record.state" title="提交工序完成">
                                <a-button   class="action-button" type="link" @click="finishProcess(record)">
                                    <CheckOutlined class="pointer"/>
                                </a-button>
                            </a-tooltip>
                            <a-popconfirm
                                    v-if="!viewModel && !record.state"
                                    ok-text="是"
                                    cancel-text="否"
                                    class="action-button"
                                    title="确定删除吗?"
                                    @confirm="deleteData(record)">
                                <DeleteOutlined  style="color: red" class="pointer"/>
                            </a-popconfirm>
                        </div>
                    </template>
                </LTable>
            </div>
            <div v-if="currentProcess.title" style="width:50%;border: #c8c8c8 dashed 1px;">
                <LTable :data-source="dataSource2" :columns="tableColumns2">
                    <template #action="{record}">
                        <div class="action-column">
                            <a-button @click="viewUser(record)" type="link">查看</a-button>
                            <a-button v-if="!record.state" @click="editUser(record)" type="link">编辑</a-button>
                            <a-button v-if="!record.state" @click="deleteData2(record)" type="link">删除</a-button>
                        </div>
                    </template>
                </LTable>
            </div>
        </div>
    </div>
    <a-modal v-model:open="visible"
             destroy-on-close
             title="工序详情"
             :footer="null">
        <LForm :view-model="vModel" :form-columns="formColumns" :form-data="currentProcess">
            <template #action="{record}">
                <a-button  type="primary"  @click="saveData(record)">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
    <a-modal v-model:open="visible2"
             destroy-on-close
             title="工序成员"
             :footer="null">
        <a-row v-if="!vModel">
            <a-col :span="8">
                <div class="form-label" style="margin-right: 10px">选择人员</div>
            </a-col>
            <a-col :span="12">
                <div class="form-value"><UserPickerS v-model:value="user" :option="option"/></div>
            </a-col>
        </a-row>
        <LForm :label-span="8" :form-data="currentMember" :form-columns="tableColumns2" :view-model="vModel">
            <template #action="{record}">
                <a-button  type="primary" @click="saveMember(record)">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
    <a-modal v-model:open="visible4"
             destroy-on-close
             title="提交任务完成情况"
             :footer="null">
        <a-row style="margin: 10px">
            <a-col :span="8">
                <div class="form-label" style="margin-right: 10px">完成时间</div>
            </a-col>
            <a-col :span="16">
                <div class="form-value" >
                    <a-date-picker style="width: 200px" v-model:value="taskDate" />
                </div>
            </a-col>
        </a-row>
        <a-row style="margin: 10px">
            <a-col :span="8">
                <div class="form-label" style="margin-right: 10px">完成描述</div>
            </a-col>
            <a-col :span="16">
                <div class="form-value" >
                    <a-input style="width: 200px" v-model:value="currentProcess.describe" />
                </div>
            </a-col>
        </a-row>
        <a-button @click="saveData2"  type="primary">
            提交
        </a-button>
    </a-modal>
    <a-modal v-model:open="visible5"
             destroy-on-close
             width="800px"
             title="任务延期申请"
             :footer="null">
        <delayApply :record="currentProcess" type="2" :dead-line="currentProcess.endDate"/>
    </a-modal>
</template>

<script>
    import LForm from "../../../../components/form/LForm";
    import {
        CheckOutlined,
        DeleteOutlined,
        EditFilled, FallOutlined,
        OrderedListOutlined,
        PlusOutlined,
        SearchOutlined, SyncOutlined,
        TeamOutlined, UploadOutlined
    } from "@ant-design/icons-vue";
    import LTable from "../../../../components/table/LTable";
    import UserPickerS from "../../../common/UserPickerS";
    import DelayApply from "../../../WorkAdministration/DelayManager/DelayApply";
    import {ArrayFilter} from "../../../../assets/utils/general";
    import item_task_process_employee from "../../../TableStruct/item_task_process_employee";
    import item_qc_process from "../../../TableStruct/item_qc_process";
    import qcProcess from "../../../../assets/api/qcProcess";
    import dayjs from "dayjs";
    import processEmployee from "../../../../assets/api/processEmployee";
    import Column from "@/assets/tables/column";
    import ColumnType from "@/assets/tables/column_type"
    export default {
        name: "QCTaskProcess",
        props:["viewModel","apply","departmentId"],
        emits:["refresh"],
        components:{
            LForm,
            PlusOutlined,DeleteOutlined,SearchOutlined,EditFilled,TeamOutlined,OrderedListOutlined,
            CheckOutlined, FallOutlined,SyncOutlined,UploadOutlined,
            LTable,UserPickerS,DelayApply
        },
        computed: {
            source(){
                return ArrayFilter(this.dataSource,this.filter);
            }
        },
        watch:{
            currentProcess(){
                this.initMember();
            }
        },
        data(){
            return{
                table:null,
                table2:null,
                dataSource:null,//提供table的DataSource
                tableColumns:[], //提供table的字-段集 this.table.getTableColumns()
                loading:false,//table的loading状态
                formColumns:[],
                currentProcess:{},//提供Form的formData
                currentMember:{},//提供Form的formData
                vModel:true,
                visible:false,
                visible2:false,
                visible3:false,
                visible4:false,
                visible5:false,
                dataSource2: [],//提供table的DataSource
                tableColumns2: [
                    new Column("成员","userName",ColumnType.String,false).setTableView(100),
                    new Column("工作内容","content",ColumnType.String,true).setTableView(200),
                    new Column("任务开始时间","startDate",ColumnType.Date,true),
                    new Column("完成期限","endDate",ColumnType.Date,true),
                    new Column("实际完成时间","completeDate",ColumnType.Date,false),
                    new Column("完成状态","state",ColumnType.Enum,false).setTableView(100)
                        .setDictEnum("state"),
                    new Column("完成情况说明","describe",ColumnType.String,false),
                    new Column("备注","remarks",ColumnType.String,true),
                    new Column("操作","actions","actions").setTableView(200)
                ], //提供table的字-段集 this.table.getTableColumns()
                tableColumns3: [
                    new Column("分配人员","userName",ColumnType.String).setTableView(80),
                    new Column("参与工序","processTitle",ColumnType.String).setTableView(400),
                ], //提供table的字-段集 this.table.getTableColumns()
                user:null,
                dataSource3:[],
                taskDate:null,
                filter:{},
                option:[]
            }
        },
        methods:{
            init(){
                this.table = new item_qc_process();
                this.table2 = new item_task_process_employee();
                /**
                 * 设定允许选择的时间范围
                 * */
                let sDate = this.table.columnArray.find(item=>item.dataIndex ==="startDate");
                let eDate = this.table.columnArray.find(item=>item.dataIndex ==="endDate");
                sDate.setEnableTime([this.apply.createTime,this.apply.planCommitDate]);
                eDate.setEnableTime([this.apply.createTime,this.apply.planCommitDate]);
                this.table2.columnArray.find(item=>item.dataIndex ==="startDate").setEnableTime([this.apply.createTime,this.apply.planCommitDate]);
                this.table2.columnArray.find(item=>item.dataIndex ==="endDate").setEnableTime([this.apply.createTime,this.apply.planCommitDate]);
                this.tableColumns = this.table.getTableColumns();
                this.formColumns = this.table.getFormColumns();
                let data = {applyId:this.apply.id};
                qcProcess.queryAll.urlPost(data,res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                    }
                });
            },
            modalAdd(){
                this.visible = true;
                this.vModel = false;
                this.formColumns = this.table.getFormColumns();
                this.currentProcess = this.table.getFormData();
                this.currentProcess.startDate = this.apply.createTime;
                this.currentProcess.endDate = this.apply.planCommitDate;
            },
            showDetail(record){
                this.formColumns = this.table.getFormColumns();
                this.currentProcess = record;
                this.visible = true;
                this.vModel = true;
            },
            editDetail(record){
                this.currentProcess = record;
                this.visible = true;
                this.vModel = false;
            },
            addUser(record){
                this.currentProcess = record;
                this.vModel = false;
                this.visible2 = true;
                this.currentMember = {};
                this.user = undefined;
            },
            deleteData(record){
                let data = {id:record.id};
                qcProcess.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.dataSource = this.dataSource.filter(item =>item.id !== record.id)
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData(record){
                record.applyId = this.apply.id;
                qcProcess.saveOrUpd.post(record,res=>{
                    if(res.data.flag){
                        this.visible = false;
                        this.init()
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData2(){
                this.currentProcess.completeDate = this.taskDate.valueOf();
                qcProcess.saveOrUpd.post(this.currentProcess,res=>{
                    if(res.data.flag){
                        this.visible4 = false;
                        this.init()
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveMember(record){
                record.processId = this.currentProcess.id;
                record.userId = this.user;
                record.processType = "2";
                record.personType = "1";
                record.startDate = dayjs(record.startDate).format("YYYY-MM-DD");
                record.endDate = dayjs(record.endDate).format("YYYY-MM-DD");
                processEmployee.saveOrUpd.post(record,res=>{
                    if(res.data.flag){
                        this.visible2 = false;
                        this.$message.success(res.data.msg);
                        this.initMember()
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            deleteData2(record){
                let data = {id:record.id};
                processEmployee.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.dataSource2 = this.dataSource2.filter(item=>item.id !== record.id)
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            finishProcess(record){
                this.visible4 = true;
                this.currentProcess = record;
            },
            delayApply(record){
                this.currentProcess = record;
                this.visible5 = true;
            },
            initMember(){
                if(this.currentProcess.id){
                    let data = {processId:this.currentProcess.id};
                    this.loading = true ;
                    processEmployee.queryAll.post(data,res=>{
                        this.loading = false ;
                        if(res.data.flag){
                            this.dataSource2 = res.data.data;
                        }
                        else{
                            this.dataSource2=[];
                        }
                    });
                }
            },
            viewUser(item){
                this.currentMember = item;
                this.user = item.userId;
                this.visible2 = true;
                this.vModel = true;
            },
            editUser(item){
                this.user = item.userId;
                this.currentMember = item;
                this.option = [{
                    id:item.userId,
                    name:item.userName
                }]
                this.visible2 = true;
                this.vModel = false;
            },
            rowClick(record){
                this.currentProcess = record;
            },
        },
        created() {
            this.init();
        }
    }
</script>

<style scoped>

</style>