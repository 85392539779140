/** 
* 自动生成文件，请勿修改 
* @name: 项目:任务月度完成进度
* @description: 任务月度完成进度(24-7月新增)
* @create: 2024/9/29 15:41:49
* @swagger: 2.0
* 合计:6条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const taskMonthRatio = { 
	/** 1 summary:删除-单条
	description:参数：单条数据的主键id
	method:post
	params:需要配合Column函数使用
	*/ 
	delete : new APIObject.postObject("/api/taskMonthRatio/delete.do"), 
	/** 2 summary:查询-单条
	description:参数：单条数据的主键id,
	method:post
	params:需要配合Column函数使用
	*/ 
	queryOne : new APIObject.postObject("/api/taskMonthRatio/queryOne.do"), 
	/** 3 summary:查询-不分页
	description:参数：参数：项目id;任务id;部门id;月份
	method:post
	params:需要配合Column函数使用
	*/ 
	queryAll : new APIObject.postObject("/api/taskMonthRatio/queryAll.do"), 
	/** 4 summary:删除-批量
	description:参数：数据的主键id,逗号分隔多条：1,2,3,4.。
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDelete : new APIObject.postObject("/api/taskMonthRatio/batchDelete.do"), 
	/** 5 summary:查询-分页
	description:参数：pageBean(分页对象，limit，page即可)；参数：项目id;任务id;部门id;月份
	method:post
	params:需要配合Column函数使用
	*/ 
	queryPage : new APIObject.postObject("/api/taskMonthRatio/queryPage.do"), 
	/** 6 summary:新增-单条
	description:参数：对象taskMonthRatioVO
	method:post
	params:需要配合Column函数使用
	*/ 
	saveOrUpd : new APIObject.postObject("/api/taskMonthRatio/saveOrUpd.do"), 
};
export default taskMonthRatio;