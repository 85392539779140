<!--
* @program: TYHOA2 
* @author: ly
* @component:ProjectInvoicePayOff 
* @description: 
* @create: 2024-07-26 14:16
-->
<template>
    <div class="form-title">支出发票列表
        <a-button  type="link"  @click="refresh">
            <template #icon><SyncOutlined /></template>
        </a-button>
    </div>
    <div style="display: flex;margin-left: 30px;align-items: center;">
        <a-button  type="dashed" @click="addData">
            <template #icon><PlusOutlined /></template>添加发票
        </a-button>
        <div style="margin-left: 10px;">已开票合计: <span class="form-value">{{sum.invoiceAmount.toFixed(2)}} 元</span></div>
        <div style="margin-left: 10px;">已付款合计: <span class="form-value">{{sum.payAmount.toFixed(2)}} 元</span></div>
        <div style="margin-left: 10px;">已付款占比: <span class="form-value">{{sum.per.toFixed(2)}} %</span></div>
    </div>
    <LTable style="height: 100%" :columns="tableColumns" :data-source="dataSource">
        <template #action="{index,record}">
            <div class="action-column">
                <a-tooltip  title="添加付款">
                    <a-button  type="link"  @click="addPayed(record)">
                        <template #icon><PlusOutlined /></template>
                    </a-button>
                </a-tooltip>
                <a-button  type="link"  @click="showDetail(record)">
                    <template #icon><SearchOutlined /></template>
                </a-button>
                <a-button  type="link"  @click="editDetail(record)">
                    <template #icon><EditOutlined /></template>
                </a-button>
                <a-popconfirm
                        v-if="deleteCheck(record)"
                        ok-text="是"
                        cancel-text="否"
                        class="action-button"
                        title="确定删除吗?"
                        @confirm="deleteData(index,record)">
                    <DeleteOutlined  style="color: red" class="pointer"/>
                </a-popconfirm>
            </div>
        </template>
    </LTable>

    <a-modal v-model:open="visible"
             destroy-on-close
             title="添加付款发票"
             :maskClosable="vModel"
             :footer="null">
        <SelectContractType2 v-if="!currentRecord.id && !vModel" @change="handleChange" :contract="record?.contractInfoDTOs"/>
        <a-divider />
        <a-row v-if="!vModel">
            <a-col :span="4">
                <span class="form-label">收款方</span>
            </a-col>
            <a-col :span="20">
                <span v-if="contract" class="form-value">{{contract.associatedName}}</span>
                <CooperationUnitsPicker :option="option" style="width: 100%" v-else v-model:value="companyId" />
            </a-col>
        </a-row>
        <a-row v-if="!vModel">
            <a-col :span="4">
                <span class="form-label">付款方</span>
            </a-col>
            <a-col :span="20">
                <span v-if="contract" class="form-value">{{contract.sigCompanyName}}</span>
                <a-select style="width: 100%" v-else v-model:value="partyId" :options="companies" />
            </a-col>
        </a-row>
        <LForm title="发票信息" :view-model="vModel"  :form-columns="formColumns" :form-data="currentRecord">
            <template #action="{record}">
                <a-button @click="saveData(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>

    <a-modal v-model:open="visible2"
             destroy-on-close
             title="添加付款申请"
             :maskClosable="vModel"
             :footer="null">
        <SelectContractType2 :contract-id="currentRecord.remarks" v-if="!vModel" @change="handleChange" :contract="record?.contractInfoDTOs"/>
        <a-divider />
        <a-row v-if="!vModel">
<!--            <a-col :span="6">-->
<!--                <span class="form-value">支出账户</span>-->
<!--            </a-col>-->
<!--            <a-col :span="18">-->
<!--                <a-select :fieldNames="{key:'id', value: 'id',label:'remarks'}"-->
<!--                          allowClear-->
<!--                          placeholder="若未知，请不要选择"-->
<!--                          v-model:value="bankId" style="width: 100%" :options="bankCards">-->
<!--                </a-select>-->
<!--            </a-col>-->
            [支出账户]在通过审核之后，在【账户收付款管理】【项目外协支付】确认付款时选择
        </a-row>
        <LForm :view-model="vModel" :form-columns="formColumns2" :form-data="formData2">
            <template #action="{record}">
                <a-button @click="saveData2(record)"  type="primary">
                    提交
                </a-button>
            </template>
        </LForm>
    </a-modal>
</template>

<script>
    import {DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined, SyncOutlined} from "@ant-design/icons-vue";
    import LForm from "../../../components/form/LForm";
    import LTable from "../../../components/table/LTable";
    import Column from "../../../assets/tables/column";
    import ColumnType from "../../../assets/tables/column_type";

    import item_invoice_record_payoff from "../../TableStruct/item_invoice_record_payoff";
    import invoiceInto from "../../../assets/api/invoiceInto";
    import SelectContractType2 from "./SelectContractType2";
    import payApply from "../../../assets/api/payApply";
    import {GetDict} from "../../../assets/utils/general";
    import CooperationUnitsPicker from "../../common/CooperationUnitsPicker";

    export default {
        name: "ProjectInvoicePayOff",
        props:["record","bankCards"],
        emits:["update"],
        inject:["pProjectId"],
        components:{
            PlusOutlined,DeleteOutlined,EditOutlined,
            SyncOutlined,SearchOutlined,
            LForm,LTable,SelectContractType2,CooperationUnitsPicker
        },
        computed:{
            sum(){
                let invoiceAmount = 0;//已开票金额
                let payAmount = 0;//已收款金额
                let per = 0; //比例
                for(let item of this.dataSource){
                    invoiceAmount += item.invoiceAmount;
                    payAmount += item.payAmount;
                }
                if(invoiceAmount && payAmount){
                    per = (payAmount/invoiceAmount *100)
                }
                return {invoiceAmount,payAmount,per}
            },
            projectId(){
                if(this.record){
                    return this.record.id
                }
                else{
                    return this.pProjectId
                }
            }
        },
        data(){
            return{
                table:null,
                dataSource:[],//提供table的DataSource
                tableColumns:null, //提供table的字-段集 this.table.getTableColumns()
                loading:false,//table的loading状态
                formColumns:null, //提供Form的字段集 this.table.getFormColumns()
                formColumns2:[
                    new Column("申请支付金额","payAmount",ColumnType.Number,true),
                    new Column("支付描述","payReason",ColumnType.String,true),
                ], //提供Form的字段集 this.table.getFormColumns()
                currentRecord:null,//提供Form的formData
                formData2:null,//提供Form的formData
                visible:false,
                visible2:false,
                vModel:true,
                contract:null,
                bankId:undefined,
                partyId:undefined, //付款方ID
                option:[],
                companies:GetDict("companies"),//外协合同，付款方为我公司
                companyId:undefined, //收款方ID
                departmentId:null,
            }
        },
        methods:{
            init(){
                this.table = new item_invoice_record_payoff();
                this.tableColumns = this.table.getTableColumns();
                this.formColumns = this.table.getFormColumns();
                this.loading = true;
                invoiceInto.queryAll.post({itemId:this.projectId},res=>{
                    this.loading = false;
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                        if(this.record.contractInfoDTOs && this.record.contractInfoDTOs.length >0){
                            this.dataSource.map(item =>{
                                let contract = this.record.contractInfoDTOs.find(it=>it.id === item.remarks)
                                if(contract){
                                    item.contractName = contract.name
                                }
                                return item
                            })
                        }
                    }
                    else{
                        this.dataSource = [];
                    }
                })
            },
            refresh(){
                this.init()
            },
            addData(){
                this.contract = null;
                this.vModel = false;
                this.visible = true;
                this.currentRecord = this.table.getFormData();
                this.currentRecord.itemId = this.projectId;
                if(this.record && this.record.sigCompany){
                    this.currentRecord.partyId = this.record.sigCompany;
                }
                this.formColumns = this.table.getFormColumns();

                this.partyId = undefined;
                this.companyId = undefined;
                this.option = [];
            },
            showDetail(record){
                this.contract = null;
                this.visible = true;
                this.vModel = true;
                this.currentRecord = record;
                this.formColumns = this.table.getFormColumns();
            },
            editDetail(record){
                this.contract = null;
                this.visible = true;
                this.vModel = false;
                this.currentRecord = record;
                this.formColumns = this.table.getFormColumns();

                this.partyId = record.partyId;
                this.companyId = record.companyId;
                this.option = [record.outSourceInfoDTO];
            },
            deleteData(index,record){
                let data = {id:record.id};
                invoiceInto.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.dataSource.splice(index,1);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            saveData(record){
                if(this.contract){
                    record.partyId = this.contract.sigCompany;
                    record.companyId = this.contract.associatedKey;
                    record.remarks = this.contract.id; //暂用备注记录发票对应的合同ID
                }
                else{
                    record.partyId = this.partyId;
                    record.companyId = this.companyId;
                }
                invoiceInto.saveOrUpdate.post(record,res=>{
                    if(res.data.flag){
                        this.init();
                        this.visible = false;
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            deleteCheck(record){
                return record.payAmount === 0;
            },
            handleChange(contract){
                this.contract = contract;
            },
            addPayed(record){
                this.bankId = null;
                this.vModel = false;
                this.visible2 = true;
                this.currentRecord = record;
                this.formData2 = new item_invoice_record_payoff().getFormData();
            },
            saveData2(record){
                record.itemId = this.projectId;
                record.outSourceId = this.currentRecord.companyId;
                record.payCompanyId = this.currentRecord.partyId;
                record.contractId = this.contract.id;
                record.applyDepart = this.departmentId;
                record.invoiceId = this.currentRecord.id;
                record.bankId = this.bankId;
                payApply.saveOrUpd.post(record,res=>{
                    if(res.data.flag){
                        this.visible2 = false;
                        this.$message.success(res.data.msg);
                        this.$emit("update");
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
        },
        async created() {
            this.init();
            let myDepartments = JSON.parse(await this.$getItem("__myDepartments"));
            this.departmentId = myDepartments[0].id
        }
    }
</script>

<style scoped>

</style>