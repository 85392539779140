/** 
* 自动生成文件，请勿修改 
* @name: 报销:经费支出统计模块
* @description: 报销-经费支出统计模块-数据统计查询
* @create: 2024/9/29 15:41:49
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const fundSummary = { 
	/** 1 summary:统计支出情况-按月统计每个项目的支出情况
	description:参数：年月字符串如：2021-01
	method:post
	params:需要配合Column函数使用
	*/ 
	statiByMonth : new APIObject.postObject("/api/fundSummary/statiByMonth.do"), 
	/** 2 summary:统计支出情况-按年统计每个项目的支出情况
	description:参数：年字符串如：2021
	method:post
	params:需要配合Column函数使用
	*/ 
	statiByYear : new APIObject.postObject("/api/fundSummary/statiByYear.do"), 
	/** 3 summary:统计支出情况-
	description:参数：根据报销分类：大类id或者小类id（只传其一即可，如已经传了小类id,大类id无意义了） 年度或月度（只传其一即可，如2021或2021-04）、项目id、等来进行统计支出情况      * 入账状态entrystate：默认为1：已入账状态的数据      * 如果传入年度：统计12个月每月的支出      * 如果传入月份：统计当月每天的支出      * 若不传参数：则默认统计每天的支出（同一天的全部累加，不做类别等区分）
	method:post
	params:需要配合Column函数使用
	*/ 
	statistic : new APIObject.postObject("/api/fundSummary/statistic.do"), 
	/** 4 summary:统计支出情况-夏总说的那个大统计(导出)
	description:参数：categoryId(费用类别id),startMonth(起始年月字符串),endMonth(结束年月字符串)费用类别id：可以是大类，也可以是小类，选到小类就传小类id      * 起始年月字符串：如2021-04      * 结束年月字符串：如2021-08      * 上面2个不存在时 查询当年的每个月 1-12月      * 如果不传类别id,所有类别全部出来，不管有没有支出
	method:get
	params:需要配合Column函数使用
	*/ 
	exportExpend : new APIObject.getObject("/api/fundSummary/exportExpend.do"), 
	/** 5 summary:统计支出情况-按起始月统计各部门的支出情况
	description:参数：年月字符串如：2021-01;（增加了每个员工下每种费用类别统计支出）
	method:post
	params:需要配合Column函数使用
	*/ 
	statiMonthDep : new APIObject.postObject("/api/fundSummary/statiMonthDep.do"), 
	/** 6 summary:统计支出情况-夏总说的那个大统计
	description:参数：categoryId(费用类别id),startMonth(起始年月字符串),endMonth(结束年月字符串)费用类别id：可以是大类，也可以是小类，选到小类就传小类id      * 起始年月字符串：如2021-04      * 结束年月字符串：如2021-08      * 上面2个不存在时 查询当年的每个月 1-12月      * 如果不传类别id,所有类别全部出来，不管有没有支出
	method:post
	params:需要配合Column函数使用
	*/ 
	statisticMonth : new APIObject.postObject("/api/fundSummary/statisticMonth.do"), 
	/** 7 summary:统计支出情况-按月统计每个部门的支出情况
	description:参数：年月字符串如：2021-01;（增加了每个员工下每种费用类别统计支出）
	method:post
	params:需要配合Column函数使用
	*/ 
	statiByDepart : new APIObject.postObject("/api/fundSummary/statiByDepart.do"), 
};
export default fundSummary;