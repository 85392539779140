<template>
    <div class="column-flex" style="position: relative;overflow: hidden">
        <div class="search-bar">
            <a-button @click="refresh" type="dashed">
                <template #icon>
                    <SyncOutlined/>
                </template>
            </a-button>
            <a-segmented v-model:value="component" :options="components" />
            <div class="title-divider" style="margin-right: 10px">工序选择</div>
            <WorkSelector style="width: 300px;" :taskList="taskList" v-model:value="processEmployeeId"/>
            <a-button type="dashed" @click="addData">
                <template #icon><PlusOutlined  /></template>
                新增日志
            </a-button>
            <a-button type="dashed" @click="finishWork">
                提交工序完成
            </a-button>
        </div>
        <div class="column-flex">
            <component :is="component" :diary="myDiary" @edit="editHandle" @finish="finishWork2" :processList="processList"/>
        </div>
    </div>
    <a-modal v-model:open="visible"
             destroyOnClose
             title="质检日志">
        <div v-if="!currentDiary.id" class="form-label">只能选择尚未完成的工序，未选择则计入【其他工作】</div>
        <div v-if="currentDiary.itemName" class="form-label">{{currentDiary.itemName}}</div>
        <div v-if="currentDiary.processTitle" class="form-label">{{currentDiary.processTitle}}</div>
        <a-row v-if="!currentDiary.id" style="margin:10px">
            <a-col :span="6">
                <div class="form-label" style="margin-right: 10px">质检工序</div>
            </a-col>
            <a-col :span="18">
                <WorkSelector :taskList="taskList" v-model:value="currentDiary.processEmployeeId"/>
            </a-col>
        </a-row>
        <a-row  style="margin:10px">
            <a-col  :span="6">
                <div class="form-label" style="margin-right: 10px">日志时间</div>
            </a-col>
            <a-col :span="18">
                <a-date-picker :disabled="currentDiary.id" style="width:100%" v-model:value="currentDiary.workDate"  placeholder="选择日期"  />
            </a-col>
        </a-row>
        <a-row  style="margin:10px">
            <a-col  :span="6">
                <div class="form-label" style="margin-right: 10px">工作内容</div>
            </a-col>
            <a-col :span="18">
                <a-textarea v-model:value="currentDiary.workContent" :auto-size="{ minRows: 2, maxRows: 6 }"
                            style="width:100%" placeholder="工作内容" />
            </a-col>
        </a-row>
        <template #footer>
            <a-button @click="saveData"  type="primary">
                提交
            </a-button>
            <a-popconfirm
                    v-if="currentDiary.id"
                    ok-text="是"
                    cancel-text="否"
                    title="确定删除吗?"
                    @confirm="deleteData">
                <a-button danger>
                    删除
                </a-button>
            </a-popconfirm>
        </template>
    </a-modal>

    <a-modal v-model:open="visible2"
             destroyOnClose
             title="提交工序完成">
        <a-row style="margin:10px">
            <a-col :span="6">
                <div class="form-label" style="margin-right: 10px">工序选择</div>
            </a-col>
            <a-col :span="18">
                <WorkSelector :taskList="taskList" v-model:value="processEmployeeId"/>
            </a-col>
        </a-row>
        <a-row style="margin:10px">
            <a-col :span="6">
                <div class="form-label" style="margin-right: 10px">完成情况说明</div>
            </a-col>
            <a-col :span="18">
                <a-textarea v-model:value="describe" :auto-size="{ minRows: 2, maxRows: 6 }"
                            style="width:100%" placeholder="完成情况说明" />
            </a-col>
        </a-row>
        <template #footer>
            <a-button @click="updateWork"  type="primary">
                提交完成
            </a-button>
        </template>
    </a-modal>
</template>

<script>
    import personal_task from "../../../../assets/tables/personal_task";
    import processEmployee from "../../../../assets/api/processEmployee";
    import {EditFilled,RadarChartOutlined,SyncOutlined,SearchOutlined,PlusOutlined} from "@ant-design/icons-vue";
    import CalendarDiary from "./CalendarDiary";
    import TableDiary from "./TableDiary";
    import workSituation from "../../../../assets/api/workSituation";
    import WorkSelector from "./WorkSelector";
    import {uuid} from "../../../../assets/utils/general";
    import dayjs from "dayjs";
    import QCFinishWorkList from "./QCFinishWorkList";
    import QCMyReport from "./QCMyReport";
    export default {
        name:"worksDiary",
        components:{
            EditFilled,RadarChartOutlined,SyncOutlined,SearchOutlined,PlusOutlined,
            CalendarDiary,TableDiary,QCFinishWorkList,WorkSelector,QCMyReport
        },
        computed:{
            taskList(){
                let taskList = [];
                for(let item of this.source){
                    let {taskTitle,taskId,itemName, processType} = item;
                    let task = taskList.find(item=>item.itemName === itemName);
                    if(task){
                        item.processTitle = (task.children.length + 1) + "." + item.processTitle;
                        task.children.push(item);
                    }
                    else{
                        item.processTitle = "1." + item.processTitle;
                        taskList.push({id:uuid(10,10),processType,selectable:false,taskId,taskTitle,itemName,children:[item]})
                    }
                }
                return taskList
            }
        },
        data(){
            return{
                component:"CalendarDiary",
                components:[
                    {value:"CalendarDiary",label:"日历日志模式"},
                    {value:"TableDiary",label:"列表日志模式"},
                    {value:"QCFinishWorkList",label:"质检工作清单"},
                ],
                table:new personal_task(),
                processList:[],
                source:[],//提供table的DataSource
                loading:false,//table的loading状态
                processType:"2",//质检工序
                userId:undefined,
                state:"0",
                filter:{

                },
                viewModel:true,
                myDiary:[],
                visible:false,
                currentDiary:{},

                visible2:false,
                describe:null,
                processEmployeeId:undefined,
            }
        },
        methods:{
            init(){
                this.loading = true;
                workSituation.queryAll.post({userId:this.userId,processType:this.processType},res=>{//{userId:this.userId}
                    this.loading = false;
                    if(res.data.flag){
                        this.myDiary = res.data.data;
                    }
                    else{
                        this.myDiary = [];
                    }
                });
            },
            init2(){
                processEmployee.queryAll.post({userId:this.userId,processType:this.processType},res=>{
                    if(res.data.flag){
                        this.processList = res.data.data;
                        this.doSearch();
                    }
                    else{
                        this.processList = [];
                    }
                });
            },
            refresh(){
                this.init();
            },
            doSearch() {
                this.source = [];
                switch (this.state) {
                    case "0":{
                        this.source = this.processList.filter(item => !item.state);
                        break;
                    }
                    case "1":{
                        this.source = this.processList.filter(item => item.state);
                        break;
                    }
                    case "2":{
                        this.source = this.processList;
                        break
                    }
                }
            },
            addData(){
                this.visible = true;
                this.currentDiary = {
                    processEmployeeId : this.processEmployeeId
                };
                this.currentDiary.workDate = dayjs();
            },
            editHandle(item){
                this.visible = true;
                let{id,workDate,processEmployeeId,workContent,processTitle,itemName} = item;
                if(id){
                    this.currentDiary = {id,processEmployeeId,workContent,processTitle,itemName};
                }
                else{
                    this.currentDiary = {
                        processEmployeeId:this.processEmployeeId,
                        workDate:workDate
                    };
                }
                this.currentDiary.workDate = dayjs(workDate);
            },
            saveData(){
                this.currentDiary.processEmployeeId = this.currentDiary.processEmployeeId?this.currentDiary.processEmployeeId:"work";
                workSituation.saveOrUpd.post(this.currentDiary,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.init();
                        this.visible = false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            deleteData(){
                let data = {
                    id:this.currentDiary.id
                };
                workSituation.delete.urlPost(data,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.init();
                        this.visible = false;
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            finishWork(){
                this.describe = null;
                this.visible2 = true;
            },
            finishWork2(processEmployee){
                this.processEmployeeId = processEmployee.id;
                this.describe = null;
                this.visible2 = true;
            },
            updateWork(){
                let record = this.processList.find(item=>item.id === this.processEmployeeId)
                record.completeDate = new Date().valueOf();
                record.state = true;
                processEmployee.saveOrUpd.post(record,res=>{
                    if(res.data.flag){
                        this.init2();
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
        },
        async created(){
            this.userId = await this.$getItem("__myID");
            this.init();
            this.init2();
        }
    }
</script>

<style scoped>

</style>